import React from 'react';
import Box from 'Components/Atoms/Box';
import ProductIcon from '../ProductIcon';
import './styles.scss';
import { Products } from 'utils/types';
import { dateHelper } from 'utils/helper';
import firebase from 'utils/firebase';
import { AppId } from 'gastronaut-shared/types/documents/restaurants';

export type MobileProductIconsProps = {
  products?: Products[];
  apps?: AppId[];
  takeAwayNotifications?: number;
  restaurantId?: null | string;
};

const PRODUCTS = [
  {
    id: 'reservations',
    product: 'light-reservation, reservations',
    label: 'Reservations',
    linkTo: (rId: string) => `${rId}/reservations/${dateHelper()}/tablePlan`,
  },
  // {
  //   id: "light-reservations",
  //   product: "light-reservation",
  //   label: "Reservations",
  //   linkTo: (rId: string) => `${rId}/reservations/${dateHelper()}/reservationList`,
  // },
  // {
  //   id: 'calendar',
  //   product: 'light-reservation, reservations',
  //   label: 'Calendar',
  //   linkTo: (rId: string) => `${rId}/calendar/${dateHelper()}`
  // },
  // {
  //   id: "guestbook",
  //   product: "light-reservation, reservations",
  //   label: "Guest Book",
  //   linkTo: (rId: string) => `${rId}/guestbook`,
  //   // hideLabel: "COMING SOON",
  // },
  // {
  //   id: "delivery",
  //   product: "delivery",
  //   label: "Take Away",
  //   linkTo: (rId: string) => `${rId}/takeAway/orders/${dateHelper()}`,
  //   // hideLabel: "COMING SOON",
  // },
  {
    id: 'entranceTicket',
    product: 'entranceTicket',
    label: 'Entrance Ticket',
    linkTo: (rId: string) => `${rId}/entranceTicket`,
    // hideLabel: "COMING SOON",
  },
  {
    id: 'phone',
    product: 'phone',
    label: 'SIA',
    linkTo: (rId: string) => `${rId}/sia`,
  },
  // {
  //   id: 'feedback',
  //   product: 'feedback, followUp',
  //   label: 'Review Rocket',
  //   linkTo: (rId: string) => `${rId}/feedback`
  // },
  // {
  //   id: 'platform',
  //   product: 'platform',
  //   label: 'Platform',
  //   linkTo: (rId: string) => `${rId}/platform-preview`
  // },
  // {
  //   id: 'vouchers',
  //   product: 'vouchers',
  //   label: 'Vouchers',
  //   hideLabel: 'COMING SOON',
  //   linkTo: (rId: string) => `${rId}/vouchers`
  // },
  // {
  //   id: 'tickets',
  //   product: 'tickets',
  //   label: 'Tickets',
  //   hideLabel: 'COMING SOON',
  //   linkTo: (rId: string) => `${rId}`
  // },
  // {
  //   id: 'order',
  //   product: 'order',
  //   label: 'Guest Order',
  //   linkTo: (rId: string) => `${rId}/order`,
  //   hideLabel: 'COMING SOON'
  // }
  {
    id: 'phone',
    product: 'general',
    app: 'sia-phone-assistant',
    label: 'Sia 2.0',
    onClick: async (rId: string | null) => {
      try {
        const user = firebase.auth().currentUser;

        const SIA_PHONE_ASSISTANT_URL = 'https://sia-app.gastronaut.ai';

        if (user) {
          const idToken = await user.getIdToken(/* forceRefresh */ true);
          // Redirect to App B with the token
          window.location.href = `${SIA_PHONE_ASSISTANT_URL}/login?token=${idToken}&restaurantId=${rId}`;
        } else {
          // Handle the case where the user is not logged in
        }
      } catch (error) {
        window.alert(error);
      }
    },
    isNew: true,
    dontShowOnHide: true,
  },
];

const MobileProductIcons = ({
  products = [],
  apps = [],
  takeAwayNotifications = 0,
  restaurantId = null,
}: MobileProductIconsProps) => {
  const filteredProducts = PRODUCTS.filter(
    (p) =>
      products.some((x) => p.product.includes(x) as any) &&
      (p.product !== 'phone' || !apps.includes('sia-phone-assistant'))
  )
    .map((p) => ({
      ...p,
      hide:
        p.id !== 'reservations' &&
        p.id !== 'entranceTicket' &&
        p.id !== 'phone' &&
        !(!p.app || apps.includes(p.app as AppId)),
    })) //to modify when more than one product
    .sort((a, b) => (a.hide ? 1 : 0) - (b.hide ? 1 : 0));

  console.log(filteredProducts);

  return (
    <Box className="mobile-product-icons">
      {filteredProducts.map((product) => (
        <ProductIcon
          key={product.id}
          {...product}
          restaurantId={restaurantId}
        />
      ))}
    </Box>
  );
};

export default MobileProductIcons;
