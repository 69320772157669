import React from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { CurrentStatus, Reservation } from 'types/reservations';
import TabCard from 'Components/Molecules/TabCard';
import ReservationCard from '../ReservationCard';
import ActionBar from './Components/ActionBar';
import { toCurrencyString } from 'utils/helper';
import Typography from 'Components/Atoms/Typography';
import { CircularProgress } from '@material-ui/core';
import { ReservationActionTypes } from 'Contexts/ReservationContext';
import { ReservationShift } from 'gastronaut-shared/types/helper/shifts';
import WaitinglistCard from '../WaitinglistCard';
import { TakeAwayOrder } from 'types/takeAway';
import useReservationList from 'CustomHooks/useReservationList';
import ShiftComments from './Components/ShiftComments';

export type LeftSideBarProps = {
  reservations: Reservation[];
  restaurantId: string;
  date: string;
  loading?: boolean;
  occassions: { id: string; title: string }[];
  spaces: { id: string; name: string }[];
  currentShift?: ReservationShift | null;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  warnings?: string[];
  active: string | null;
  setActive: (id: string | null) => void;
};

const LeftSideBar: React.FC<LeftSideBarProps> = ({
  reservations,
  date,
  loading = false,
  occassions,
  spaces,
  handleAction,
  warnings = [],
  active,
  restaurantId,
  setActive,
  currentShift = null,
}) => {
  // const [upcomingOnly, setupcomingOnly] = useState(false);

  const props = useReservationList({
    reservations,
    date,
    loading,
    occassions,
    spaces,
    handleAction,
    warnings,
    active,
    restaurantId,
    setActive,
    currentShift,
  });

  return (
    <Box
      style={{
        height: props.hasWaitingList
          ? 'calc(100vh - 124px)'
          : 'calc(100vh - 70px)',
        display: 'flex',
        flexDirection: 'column',
      }}
      id="leftSideBar"
    >
      {props.hasWaitingList && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            background: 'var(--color-paper)',
            padding: '12px',
            border: '1px solid var(--color-border)',
            borderBottom: 'none',
          }}
        >
          <Typography
            color={props.tab === 'reservations' ? 'secondary' : 'subdued'}
            variant="text-2"
            weight="medium"
            onClick={() => {
              props.settab('reservations');
              props.setvalue(0);
            }}
            style={{ cursor: 'pointer' }}
          >
            Reservierungen
          </Typography>
          <Typography
            color={props.tab === 'waitinglist' ? 'secondary' : 'subdued'}
            variant="text-2"
            weight="medium"
            onClick={() => {
              props.settab('waitinglist');
              props.setvalue(0);
            }}
            style={{ cursor: 'pointer' }}
          >
            Anfragen ({props.waitinglistCount})
          </Typography>
        </Box>
      )}
      {(props.tab === 'reservations' || !props.hasWaitingList) && (
        <TabCard
          tabs={props.tabs as any}
          id="leftSideBarCard"
          className="left-sidebar"
          value={props.value}
          onChange={props.setvalue}
          hideHeader
          experimentalScroll={false}
          style={{
            borderRadius: 0,
            minHeight: '100%',
            flexGrow: 10,
          }}
          bodyProps={{
            padding: 0,
            style: {
              overflowY: 'scroll',
              height: props.hasWaitingList
                ? 'calc(100vh - 184px)'
                : 'calc(100vh - 130px)',
            },
          }}
        >
          <ActionBar
            {...{
              ...props,
              toggleAll: () => props.setshowAll((x) => !x),
            }}
          />
          {/* If Search = Reservations with same customId */}
          {props.search && props.loadingSameCustomId && (
            <Box className="center">
              <CircularProgress />
            </Box>
          )}
          <ShiftComments />
          {props.search &&
            !props.loadingSameCustomId &&
            props.reservationsWithSameCustomId.map((resa) => (
              <ReservationCard
                occassions={occassions}
                key={'resa-' + resa.id}
                reservation={resa}
                active={active === resa.id}
                hasWarnings={warnings.includes(resa.id)}
                hideOccassion={occassions.length <= 1}
                onClick={setActive}
                handleAction={handleAction}
                isAnotherDay
              />
            ))}
          {/* Non Seated on top */}
          {props.pendingTakeAwayOrders.data &&
            props.pendingTakeAwayOrders.data.map((order) => (
              <TakeAwayOrderCard order={order} key={order.id} />
            ))}
          {props.sortedReservations
            .filter(
              (res) =>
                !['paid', 'hasOrdered'].includes(res.currentStatus) &&
                (res.currentStatus !== CurrentStatus.SEATED ||
                  res.partlySeated ||
                  (!!props.reservationSettings?.keepReservationsAtTheTop &&
                    res.startedAt &&
                    res.startedAt > Date.now() - 5 * 60000))
            )
            .map((resa) => (
              <ReservationCard
                occassions={occassions}
                key={'resa-' + resa.id}
                reservation={resa}
                active={active === resa.id}
                hasWarnings={warnings.includes(resa.id)}
                hideOccassion={occassions.length <= 1}
                onClick={setActive}
                handleAction={handleAction}
              />
            ))}
          {/* Seated at bottom */}
          {props.sortedReservations
            .filter(
              (res) =>
                ['seated', 'paid', 'hasOrdered'].includes(res.currentStatus) &&
                !res.partlySeated &&
                (!props.reservationSettings?.keepReservationsAtTheTop ||
                  !res.startedAt ||
                  res.startedAt < Date.now() - 5 * 60000)
            )
            .map((resa) => (
              <ReservationCard
                occassions={occassions}
                key={'resa-' + resa.id}
                reservation={resa}
                active={active === resa.id}
                hasWarnings={warnings.includes(resa.id)}
                hideOccassion={occassions.length <= 1}
                onClick={setActive}
                handleAction={handleAction}
              />
            ))}
          {!props.sortedReservations.length && !loading && (
            <Box
              fullSize
              style={{ minHeight: 100, maxHeight: 'calc(100% - 44px)' }}
              className="center"
              padding="md"
            >
              <Typography
                variant="text-3"
                color="subdued"
                translation="reservations"
                textAlign="center"
              >
                No corresponding reservation
              </Typography>
            </Box>
          )}
          {loading && (
            <Box
              style={{ flexGrow: 10, minHeight: '100%' }}
              className="center"
              flex
              padding="md"
            >
              <CircularProgress />
            </Box>
          )}
        </TabCard>
      )}
      {props.hasWaitingList && props.tab === 'waitinglist' && (
        <TabCard
          tabs={props.waitinglist_tabs as any}
          id="leftSideBarCard"
          className="left-sidebar"
          value={props.value}
          onChange={props.setvalue}
          hideHeader
          experimentalScroll
          style={{
            borderRadius: 0,
            minHeight: '100%',
            flexGrow: 10,
          }}
          bodyProps={{
            padding: 0,
            style: { overflowY: 'scroll', height: 'calc(100vh - 154px)' },
          }}
        >
          {props.filteredWaitingList.map((entry) => (
            <WaitinglistCard
              key={entry.id}
              entry={entry}
              hideOccassion={occassions.length <= 1}
              active={props.currentWaitinglistEntry === entry.id}
              onClick={() => {
                props.setcurrentWaitinglistEntry(entry.id);
                props.setcurrentReservation(null);
              }}
            />
          ))}
        </TabCard>
      )}
    </Box>
  );
};

export default LeftSideBar;

const TakeAwayOrderCard: React.FC<{
  order: TakeAwayOrder;
}> = ({ order }) => {
  const nameStr = order?.customer?.name || '';

  return (
    <Box
      underline
      id={order.id}
      elevation={0}
      className={`reservation-card resa-overdue`}
    >
      <Box className="space-between">
        <Typography
          variant="text-4"
          block
          className={'text-white'}
          style={{ marginTop: 4 }}
        >
          Bestellung zur Abholung
        </Typography>
      </Box>
      <Typography
        variant="text-3"
        weight="bold"
        block
        className={'text-white'}
        style={{
          marginTop: 3,
          whiteSpace: nameStr.length < 20 ? 'nowrap' : 'normal',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {nameStr}
      </Typography>
      <Typography
        variant="text-4"
        block
        className={'text-white'}
        style={{ marginTop: 4 }}
      >
        {order.time} • {toCurrencyString(order.total)}
      </Typography>
    </Box>
  );
};
