// Import the crypto module from Node.js
import * as crypto from 'crypto';
require('dotenv').config();

// Secret key to sign and verify tokens (keep this secret and secure)
const SECRET_KEY = new Date().toISOString().split('T')[0];

/**
 * Encodes (signs) a JWT with the given payload and options.
 * @param {Record<string, any>} payload - The data to encode into the JWT.
 * @param {number} expiresIn - Expiration time in seconds.
 * @returns {string} - The encoded JWT.
 */
export function encodeJWT<T extends {}>(
  payload: T,
  expiresIn?: number,
  secret?: string
): string {
  const header = { alg: 'HS256', typ: 'JWT' };
  const timestamp = Math.floor(Date.now() / 1000);
  const extendedPayload = {
    ...payload,
    iat: timestamp,
    exp: timestamp + (expiresIn ?? 1000 * 60 * 60),
  };

  const base64Header = Buffer.from(JSON.stringify(header)).toString('base64');
  const base64Payload = Buffer.from(JSON.stringify(extendedPayload)).toString(
    'base64'
  );

  const dataToSign = `${base64Header}.${base64Payload}`;
  const signature = crypto
    .createHmac('sha256', secret ?? SECRET_KEY)
    .update(dataToSign)
    .digest('base64');

  return `${dataToSign}.${signature}`;
}

/**
 * Decodes (verifies) a JWT and returns the payload if valid.
 * @param {string} token - The JWT to decode.
 * @returns {Record<string, any>} - The decoded payload.
 * @throws {Error} - If the token is invalid or expired.
 */
export function decodeJWT<T>(
  token: string,
  secret?: string
): T & { exp: number; iat: number } {
  const [base64Header, base64Payload, signature] = token.split('.');
  if (!base64Header || !base64Payload || !signature) {
    throw new Error('Invalid token format');
  }

  const dataToSign = `${base64Header}.${base64Payload}`;
  const expectedSignature = crypto
    .createHmac('sha256', secret ?? SECRET_KEY)
    .update(dataToSign)
    .digest('base64');

  if (signature !== expectedSignature) {
    console.log('signature', signature);
    console.log('expectedSignature', expectedSignature);
    throw new Error('Invalid token signature');
  }

  const payload = JSON.parse(
    Buffer.from(base64Payload, 'base64').toString('utf8')
  ) as T & { exp: number; iat: number };

  if (payload.exp && Math.floor(Date.now() / 1000) > payload.exp) {
    throw new Error('Token has expired');
  }

  return payload;
}

// // Example usage
// const examplePayload = { userId: 123, username: 'john_doe' };

// // Encode a JWT with an expiration time of 1 hour
// const token = encodeJWT(examplePayload, 3600);
// console.log('Encoded Token:', token);

// // Decode the JWT
// try {
//   const decodedPayload = decodeJWT(token);
//   console.log('Decoded Payload:', decodedPayload);
// } catch (error: any) {
//   console.error('Failed to decode JWT:', error.message);
// }
