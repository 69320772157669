import React, { useEffect, useMemo, useState, useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { classHelper, noop } from 'utils/helper';
import { Translations as TranslationKeys } from 'utils/types';
import Typography from '../../../../Components/Atoms/Typography';
import TextField from '../../../../Components/Atoms/TextField';
import RichTextField from '../../../../Components/Atoms/RichTextField';
import SwitchBox from '../../../../Components/Atoms/Switch';
import TabCard from '../../../../Components/Molecules/TabCard';
import { Add } from 'Components/Atoms/Icons';
import IconButton from '../../../../Components/Atoms/IconButton';
import OutsideAlerter from '../../../../CustomHooks/useOutsideListener';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import { Translations as TranslationOptions } from 'utils/types';
import EmailTemplateDocument, {
  DefaultType,
} from 'utils/EmailTemplateDocument';
import { ReservationContext } from 'Contexts/ReservationContext';
import Button from 'Components/Atoms/Button';
import useTextHelper from 'CustomHooks/useTextHelper';
import server from 'utils/server';
import useAuth from 'CustomHooks/useAuth';
import { Translate } from '@material-ui/icons';
import LiveHelp from '@material-ui/icons/LiveHelp';

type Translation = Record<string, string>;

type Languages = 'de' | 'en' | 'fr';

const AVAILABLE_LANGUAGES: LanguageTab[] = [
  {
    id: 'de',
    label: 'German',
  },
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'fr',
    label: 'French',
  },
];

type LanguageTab = {
  id: string;
  label: string;
};

export type Key = {
  id: string;
  richText?: boolean; // default false
  required?: boolean; // default false
  templateGroupId?: string;
  label: string;
  labelTranslation?: TranslationKeys;
  multiline?: boolean; //default false
};

const DEFAULT_KEYS: Key[] = [
  {
    id: 'title',
    label: 'Title',
    required: true,
  },
  {
    id: 'tags',
    label: 'Tags',
  },
  {
    id: 'description',
    label: 'Description',
    richText: true,
  },
];

export type TranslationsProps = {
  state: Record<string, Translation | undefined>;
  onChange: (newState: Record<string, Translation>) => void;
  defaultLanguage?: Languages;
  useGoogleTranslate?: boolean;
  translate?: string[];
  onChangeTranslate?: (newValue: string[]) => void;
  keys?: Key[];
  title?: string;
  titleTranslation?: TranslationOptions;
  deleteLanguage?: boolean;
  addLanguage?: boolean;
  flex?: boolean;
  onBlur?: (newState: Record<string, Translation>) => void;
  AdditionalElement?: (language: string) => React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  borders?: boolean;
  isEmail?: boolean;
  emailTab?: string;
  languages?: string[];
  emailTemplate?: EmailTemplateDocument<DefaultType>;
  style?: React.CSSProperties;
};

const Translations: React.FC<TranslationsProps> = ({
  state,
  onChange,
  defaultLanguage = 'de',
  useGoogleTranslate = false,
  translate = [],
  onChangeTranslate = noop,
  keys = DEFAULT_KEYS,
  AdditionalElement,
  title,
  titleTranslation,
  deleteLanguage = true,
  addLanguage = true,
  flex = false,
  onBlur,
  disabled = false,
  onClick = noop,
  borders = false,
  isEmail,
  emailTab,
  emailTemplate,
  languages = ['de', 'en'],
  style = {},
}) => {
  const { alert, restaurantId } = useContext(RestaurantContext);
  const { uid } = useAuth();

  const emailTemplateOverwrite = useMemo(
    () => emailTemplate?.occassionElementOverwrites || {},
    [emailTemplate]
  );

  const [currentLang, setCurrentLang] = useState<string>(
    defaultLanguage || Object.keys(state || {})[0]
  );
  const [showLanguages, setShowLanguages] = useState<boolean>(false);
  const [isDifferent, setIsDifferent] = useState<
    Record<string, Record<string, string[]>>
  >({});

  useEffect(() => {
    if (!emailTab || emailTab === 'standard') return;
    let diffTexts = { ...emailTemplateOverwrite };

    let tab: any = diffTexts?.[emailTab] && Object.keys(diffTexts?.[emailTab]);

    let newIsDiff: any = {};
    tab &&
      tab.forEach(
        (key: string, i: number) =>
          (newIsDiff[key] = Object.keys(diffTexts?.[emailTab]?.[key]))
      );

    setIsDifferent({ ...isDifferent, [emailTab]: newIsDiff });
  }, [emailTemplateOverwrite, emailTab]);

  const tabs = useMemo(() => {
    const a: {
      id: string;
      label: string;
      translation: string;
      cancable?: boolean;
    }[] = [];

    Object.keys(state).forEach((lang) => {
      let tab = {
        id: lang,
        label: AVAILABLE_LANGUAGES.find((l) => l.id === lang)?.label || lang,
        translation: 'common',
        cancelable: deleteLanguage && lang !== 'de',
      };

      if (lang === 'de') {
        a.unshift(tab);
      } else {
        a.push(tab);
      }
    });

    return a;
  }, [state]);

  const handleChange = (e: any) => {
    const {
      target: { name, value: val },
    } = e;
    onChange({
      ...state,
      [currentLang]: { ...state[currentLang], [name]: val },
    } as Record<string, Translation>);
    onChangeTranslate([...translate, val]);
  };

  // setIsDifferent

  const onOccassionSwitch = (checked: boolean, keyId: string) => {
    if (!emailTab) return;

    if (checked) {
      if (isDifferent?.[emailTab]?.[currentLang]) {
        if (isDifferent?.[emailTab]?.[currentLang]?.includes(keyId)) {
          setIsDifferent({
            ...isDifferent,
            [emailTab]: {
              ...isDifferent?.[emailTab],
              [currentLang]: isDifferent?.[emailTab]?.[currentLang]?.filter(
                (iD) => iD !== keyId
              ),
            },
          });
        } else {
          setIsDifferent({
            ...isDifferent,
            [emailTab]: {
              ...isDifferent?.[emailTab],
              [currentLang]: [...isDifferent?.[emailTab]?.[currentLang], keyId],
            },
          });
          emailTemplateOverwrite &&
            emailTemplate?.onOccassionLocalChange(currentLang, emailTab, {
              ...emailTemplateOverwrite,
              [emailTab]: {
                ...emailTemplateOverwrite[emailTab],
                [currentLang]: {
                  ...emailTemplateOverwrite[emailTab]?.[currentLang],
                  [keyId]: state?.[currentLang]?.[keyId],
                },
              },
            });
        }
      } else {
        setIsDifferent({
          ...isDifferent,
          [emailTab]: {
            ...isDifferent[emailTab],
            [currentLang]: [keyId],
          },
        });
        emailTemplateOverwrite &&
          emailTemplate?.onOccassionLocalChange(currentLang, emailTab, {
            ...emailTemplateOverwrite,
            [emailTab]: {
              ...emailTemplateOverwrite[emailTab],
              [currentLang]: {
                ...emailTemplateOverwrite[emailTab]?.[currentLang],
                [keyId]: state?.[currentLang]?.[keyId],
              },
            },
          });
      }
    } else {
      if (
        emailTemplateOverwrite &&
        (keyId === 'title' ||
          keyId === 'emailTitle' ||
          keyId === 'description' ||
          keyId === 'disclaimer')
      ) {
        let copy = { ...emailTemplateOverwrite };
        let newIsDifferent = {
          ...isDifferent,
          [emailTab]: {
            ...isDifferent[emailTab],
            [currentLang]: isDifferent?.[emailTab]?.[currentLang].filter(
              (iD) => iD !== keyId
            ),
          },
        };

        delete copy[emailTab]?.[currentLang]?.[keyId];

        if (copy && Object.keys(copy).length) {
          if (Object.keys(copy) && !Object.keys(copy?.[emailTab]).length) {
            delete copy[emailTab];
            delete newIsDifferent[emailTab];
          }

          if (!Object.keys(copy?.[emailTab]?.[currentLang]).length) {
            delete copy[emailTab]?.[currentLang];
            delete newIsDifferent[emailTab]?.[currentLang];
          }
        }

        setIsDifferent(newIsDifferent);
        emailTemplate?.onOccassionLocalChange(currentLang, emailTab, copy);
      }
    }
  };

  const handleEmailChange = (e: any, keyId?: string) => {
    const {
      target: { name, value: val },
    } = e;
    emailTab &&
      emailTemplate?.onOccassionLocalChange(currentLang, emailTab, {
        ...emailTemplateOverwrite,
        [emailTab]: {
          ...emailTemplateOverwrite[emailTab],
          [currentLang]: {
            ...emailTemplateOverwrite[emailTab]?.[currentLang],
            [keyId ?? name]: val ?? state[currentLang]?.[keyId ?? name],
          },
        },
      });
  };

  const handleBlur = (name: string, value: string) => {
    console.log('handleBlur', !!onBlur, name, value);

    if (!onBlur) {
      handleChange({ target: { name, value } });
    } else {
      handleChange({ target: { name, value } });

      onBlur({
        ...state,
        [currentLang]: { ...state[currentLang], [name]: value },
      } as Record<string, Translation>);
    }
  };

  const toggleShowLanguages = () => setShowLanguages(!showLanguages);

  const addLanguageHandler = (lang: LanguageTab) => {
    let obj: Record<string, string> = keys.reduce(
      (acc, cV) => ({ ...acc, [cV.id]: '' }),
      {}
    );

    onChange({ ...state, [lang.id]: obj } as Record<string, Translation>);
    setCurrentLang(lang.id);
  };

  const removeLanguageHandler = (label: string) => {
    alert({
      title: 'Are you sure?',
      description: 'Deleted Translation can not be restored',
      descriptionTranslation: 'settings',
      maxWidth: 'xs',
      fullWidth: true,
      onSubmit: () => {
        setCurrentLang(defaultLanguage);
        const langId = label.substr(label.indexOf('-') + 1);
        const newObj = Object.keys(state)
          .filter((l) => l !== langId)
          .reduce((acc, cV) => ({ ...acc, [cV]: state[cV] }), {});
        onChange(newObj);
      },
    });
  };

  const plusButtonComponent = (
    <OutsideAlerter stateHandler={() => setShowLanguages(false)}>
      <div className="plus-button-container">
        <IconButton aria-label="" onClick={(e) => {}} size="medium">
          <Add onClick={toggleShowLanguages} />
        </IconButton>
        {showLanguages && (
          <div className="langs-popup">
            {AVAILABLE_LANGUAGES.filter(
              (l) => !l || languages?.includes(l.id)
            ).map(
              (lang) =>
                !Object.keys(state).includes(lang.id) && (
                  <div
                    className="item"
                    onClick={() => addLanguageHandler(lang)}
                  >
                    <Typography key={lang.id} variant="text-3">
                      {lang.label}
                    </Typography>
                  </div>
                )
            )}
          </div>
        )}
      </div>
    </OutsideAlerter>
  );

  useEffect(() => {
    if (!currentLang) setCurrentLang(Object.keys(state)[0] || defaultLanguage);
  }, [state]);

  const classNames = classHelper([
    'translations-container',
    borders && 'borders',
  ]);

  const { initTextHelper } = useTextHelper();

  const handleTranslation = async (key: string, lang = 'en') => {
    let text = state?.['de']?.[key];

    const { data } = await server.post<{
      text: string;
      translatedText: string;
      from: string;
      to: string;
      format: string;
    }>(`/v03/other/translate/${restaurantId}`, { text, userId: uid, to: lang });

    onChange({
      ...(state ?? {}),
      [lang]: { ...(state?.[lang] ?? {}), [key]: data.translatedText },
    } as Record<string, Translation>);

    if (onBlur) {
      onBlur({
        ...(state ?? {}),
        [lang]: { ...(state?.[lang] ?? {}), [key]: data.translatedText },
      } as Record<string, Translation>);

      console.log('new', key, lang, data, {
        ...(state ?? {}),
        [lang]: { ...(state?.[lang] ?? {}), [key]: data.translatedText },
      });
    }
  };

  return (
    <Box className={classNames} style={style}>
      {!!title && (
        <Typography
          variant="h5"
          color="textPrimary"
          translation={titleTranslation}
        >
          {title}
        </Typography>
      )}

      {isEmail && (
        <TabCard
          id="langs"
          tabs={tabs as any}
          value={currentLang}
          onChange={(val) => {
            document
              .querySelectorAll('[contenteditable="true"]')
              ?.forEach?.((d: any) => d?.blur?.());
            setCurrentLang(val as string);
          }}
          hideHeader={true}
          subHeaderRight={
            addLanguage &&
            (!languages || languages.length > Object.keys(state).length)
              ? plusButtonComponent
              : null
          }
          onCloseClick={(id) => removeLanguageHandler(id)}
        >
          <Box flex={flex} className={flex ? 'space-between' : ''}>
            {keys.map((k: Key) =>
              !k.richText ? (
                <div className="textfield-container" key={k.id}>
                  <div
                    className={classHelper([
                      !k.multiline && 'space-between flex-start gap-xs',
                    ])}
                  >
                    <TextField
                      multiline={k.multiline ?? false}
                      fullWidth
                      name={k.id}
                      value={
                        (emailTab &&
                          (k.id === 'title' ||
                            k.id === 'emailTitle' ||
                            k.id === 'description' ||
                            k.id === 'disclaimer') &&
                          emailTemplateOverwrite[emailTab]?.[
                            currentLang || defaultLanguage
                          ]?.[k.id]) ||
                        ''
                      }
                      onChange={handleEmailChange}
                      disabled={
                        disabled ||
                        (emailTab &&
                        !isDifferent?.[emailTab]?.[currentLang]?.includes(k.id)
                          ? true
                          : false)
                      }
                      label={k.label}
                      labelTranslation={k.labelTranslation}
                      onBlur={(e) =>
                        !!onBlur ? handleBlur(k.id, e.target.value) : noop
                      }
                      onClick={onClick}
                      style={!k.multiline ? { flex: 1 } : {}}
                    />
                    {!!k.templateGroupId && currentLang === 'de' && (
                      <Button
                        variant="default"
                        style={{ width: '47px' }}
                        onClick={() =>
                          initTextHelper({
                            groupId: k.templateGroupId ?? '',
                            onSubmit: (result) => handleBlur(k.id, result),
                          })
                        }
                        translation="common"
                      >
                        {k.multiline ? 'Get Help With Text' : <LiveHelp />}
                      </Button>
                    )}
                    {currentLang !== 'de' && (
                      <Button
                        variant="default"
                        onClick={() => handleTranslation(k.id, currentLang)}
                        translation="common"
                      >
                        {k.multiline ? (
                          'Aus dem deutschen Übersetzen'
                        ) : (
                          <Translate />
                        )}
                      </Button>
                    )}
                  </div>
                  <SwitchBox
                    name={k.id}
                    color="primary"
                    translation="settings"
                    checked={
                      emailTab &&
                      !isDifferent?.[emailTab]?.[currentLang]?.includes(k.id)
                        ? false
                        : true
                    }
                    onChange={(e, checked) => {
                      onOccassionSwitch(checked, e.target.name);
                    }}
                    label="Is different from default text?"
                  />
                </div>
              ) : (
                <div
                  key={k.id}
                  className="rich-textfield-container"
                  style={flex ? { width: '45%' } : {}}
                >
                  <label className="text-3 rich-textfield-container-label">
                    <Typography
                      translation={k.labelTranslation}
                      variant="text-3"
                    >
                      {k.label}
                    </Typography>
                  </label>
                  <RichTextField
                    id={k.id}
                    templateGroupId={
                      currentLang === 'de' ? k.templateGroupId : undefined
                    }
                    onTranslate={
                      currentLang !== 'de' &&
                      (() => handleTranslation(k.id, currentLang))
                    }
                    value={
                      (emailTab &&
                        (k.id === 'title' ||
                          k.id === 'emailTitle' ||
                          k.id === 'description' ||
                          k.id === 'disclaimer') &&
                        emailTemplateOverwrite[emailTab]?.[
                          currentLang || defaultLanguage
                        ]?.[k.id]) ||
                      ''
                    }
                    onChange={(value) =>
                      handleEmailChange({ target: { name: k.id, value } })
                    }
                    onBlur={(value) => handleBlur(k.id, value)}
                    disabled={
                      disabled ||
                      useGoogleTranslate ||
                      (emailTab &&
                      !isDifferent?.[emailTab]?.[currentLang]?.includes(k.id)
                        ? true
                        : false)
                    }
                    onClick={onClick}
                  />
                  <SwitchBox
                    color="primary"
                    checked={
                      emailTab &&
                      !isDifferent?.[emailTab]?.[currentLang]?.includes(k.id)
                        ? false
                        : true
                    }
                    onChange={(e, checked) => {
                      onOccassionSwitch(checked, k.id);
                    }}
                    translation="settings"
                    label="Is different from default text?"
                  />
                </div>
              )
            )}
            {!!AdditionalElement && AdditionalElement(currentLang)}
          </Box>
        </TabCard>
      )}

      {!isEmail && (
        <TabCard
          id="langs"
          tabs={tabs as any}
          value={currentLang}
          onChange={(val) => {
            document
              .querySelectorAll('[contenteditable="true"]')
              ?.forEach?.((d: any) => d?.blur?.());
            setCurrentLang(val as string);
          }}
          hideHeader={true}
          subHeaderRight={addLanguage ? plusButtonComponent : null}
          onCloseClick={(id) => removeLanguageHandler(id)}
        >
          {useGoogleTranslate && currentLang !== defaultLanguage && (
            <SwitchBox
              name="google translate"
              label="Use Google Translate to automatically translate this texts from German"
              translation="common"
              checked={translate.includes(currentLang)}
              onChange={() =>
                onChangeTranslate(
                  translate.includes(currentLang)
                    ? translate.filter((l) => l !== currentLang)
                    : [...translate, currentLang]
                )
              }
            />
          )}
          <Box flex={flex} className={flex ? 'space-between align-start' : ''}>
            {keys.map((k: Key) =>
              !k.richText ? (
                <div
                  key={k.id}
                  className={classHelper([
                    'textfield-container',
                    !k.multiline && 'space-between flex-start gap-xs',
                  ])}
                >
                  <TextField
                    multiline={k.multiline ?? false}
                    fullWidth
                    name={k.id}
                    value={
                      state?.[currentLang || defaultLanguage]?.[k.id] || ''
                    }
                    onChange={handleChange}
                    disabled={disabled || translate.includes(currentLang)}
                    label={k.label}
                    labelTranslation={k.labelTranslation}
                    onBlur={(e) => handleBlur(k.id, e.target.value)}
                    onClick={onClick}
                    style={!k.multiline ? { flex: 1 } : {}}
                    // style={k.multiline ? { height: 100 } : {}}
                  />
                  {!!k.templateGroupId && currentLang === 'de' && (
                    <Button
                      variant="transparent"
                      style={{ width: '47px' }}
                      onClick={() =>
                        initTextHelper({
                          groupId: k.templateGroupId ?? '',
                          onSubmit: (result) => handleBlur(k.id, result),
                        })
                      }
                      translation="common"
                    >
                      {k.multiline ? 'Get Help With Text' : <LiveHelp />}
                    </Button>
                  )}
                  {currentLang !== 'de' && (
                    <Button
                      variant="transparent"
                      onClick={() => handleTranslation(k.id, currentLang)}
                      translation="common"
                    >
                      {k.multiline ? (
                        'Aus dem deutschen Übersetzen'
                      ) : (
                        <Translate />
                      )}
                    </Button>
                  )}
                </div>
              ) : (
                <div
                  key={k.id}
                  className="rich-textfield-container"
                  style={flex ? { width: '45%' } : {}}
                >
                  <label className="text-3 rich-textfield-container-label">
                    <Typography
                      translation={k.labelTranslation}
                      variant="text-3"
                    >
                      {k.label}
                    </Typography>
                  </label>
                  <RichTextField
                    id={k.id}
                    templateGroupId={
                      currentLang === 'de' ? k.templateGroupId : undefined
                    }
                    value={
                      state?.[currentLang || defaultLanguage]?.[k.id] || ''
                    }
                    onChange={(value) =>
                      handleChange({ target: { name: k.id, value } })
                    }
                    onBlur={(value) => handleBlur(k.id, value)}
                    disabled={disabled || useGoogleTranslate}
                    onClick={onClick}
                    onTranslate={
                      currentLang !== 'de' &&
                      (() => handleTranslation(k.id, currentLang))
                    }
                  />
                </div>
              )
            )}
            {!!AdditionalElement && AdditionalElement(currentLang)}
          </Box>
        </TabCard>
      )}
    </Box>
  );
};

export default Translations;
