import Box from 'Components/Atoms/Box';
import Checkbox from 'Components/Atoms/Checkbox';
import Typography from 'Components/Atoms/Typography';
import {
  ReservationActionTypes,
  useReservationContext,
} from 'Contexts/ReservationContext';
import useDocument from 'CustomHooks/useDocument';
import { RelationshipDocument } from 'gastronaut-shared/types/documents';
import React, { useMemo, useEffect } from 'react';
import { Reservation } from 'types/reservations';
import {
  toEuropeanDate,
  capitalizeWord,
  blockToTime,
  noop,
  toCurrencyString,
  classHelper,
} from 'utils/helper';
import AttributeDropdown from '../../AttributeDropdown';
import MinimumConsumption from '../../MinimumConsumption';
import TicketInformationV02 from '../../TicketInformationV02';
import NoShowFee from './NoShowFee';
import TicketInformation from './TicketInformation';
import { AddOnOrder, CartItem } from 'App/Experiences/types/addOnOrder';
import RegularTableButton from './RegularTableButton';
import useCollection from 'CustomHooks/useCollection';
import { CustomDataValue } from 'gastronaut-shared/types/helper/customElements';
import ExperienceVoucher from './ExperienceVoucher';
import axios from 'axios';
import CommentModal from 'Components/Molecules/CommentModal';
import Warning from '../../Warning';
import useRestaurant from 'CustomHooks/useRestaurant';
import EditableField from './EditableField';

export type ReservationDetailsProp = {
  reservation: Reservation;
  occassions?: { id: string; title: string }[];
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  setHasOverflow: (value: { bottom: boolean; top: boolean }) => void;
  handleScrolling?: (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => void;
};

function toRelationshipId(guestId: string | undefined, restaurantId: string) {
  if (!guestId) {
    return null;
  } else if (guestId.includes(restaurantId)) {
    return guestId;
  } else {
    return `${guestId}-${restaurantId}`;
  }
}

export function stringifyCustomDataValue(value: CustomDataValue, key?: string) {
  if (key === 'balance') {
    return toCurrencyString(value as number, '€', false, 1);
  }
  if (typeof value === 'string') {
    if (value.startsWith('http')) {
      return (
        <a href={value} target="_blank" rel="noreferrer">
          Anhang
        </a>
      );
    }
    return value;
  } else if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'boolean') {
    return value ? 'Ja' : 'Nein';
  } else {
    return '';
  }
}

const ReservationDetails = ({
  reservation,
  occassions = [],
  onReservationAction = noop,
  setHasOverflow,
  handleScrolling = noop,
}: ReservationDetailsProp) => {
  const occassionString =
    occassions?.find((o) => o.id === reservation.occassion)?.title ||
    capitalizeWord(reservation.occassion);

  const detailsFields = useMemo(() => {
    return [
      {
        label: 'Status',
        value: capitalizeWord(reservation.currentStatus),
      },
      {
        label: 'Date',
        value: toEuropeanDate(reservation.date),
      },
      {
        label: 'Occassion',
        value: occassionString,
        align: 'right',
      },
      {
        label: 'Telephone',
        value: reservation.guest.phone,
      },
      {
        label: 'Time',
        value: reservation.time,
      },
      {
        label: 'Reservation Length',
        value: blockToTime(reservation.reservationLength || 0),
      },
      {
        label: 'Persons',
        value: reservation.guests,
      },
      {
        label: 'Tables',
        value:
          reservation?.tables && reservation?.tables?.length > 0
            ? reservation?.tables?.join(', ')
            : reservation?.tableStr ?? '',
      },
      {
        label: 'Custom Id',
        value: reservation.customId,
      },
    ];
  }, [reservation]);

  useEffect(() => {
    const container = document.getElementById('details-container');
    if (!!container) {
      const overFlow = container.clientHeight < container.scrollHeight;
      setHasOverflow({ bottom: overFlow, top: false });
    }
  }, [setHasOverflow]);

  const { experimentalFlags } = useRestaurant();

  const [relationship] = useDocument<RelationshipDocument>(
    'relationships',
    toRelationshipId(
      reservation.guest.id || reservation.guest?.guestId,
      reservation.restaurant
    )
  );

  const guestComment = relationship?.data?.guestComment || '';

  useEffect(() => {
    if (
      !(reservation.guest.id || reservation.guest?.guestId) &&
      !reservation.walkIn &&
      !reservation.validTill
    ) {
      axios.post(
        `https://europe-west3-schillingroofbarhd.cloudfunctions.net/createGuestWhenMissing`,
        {
          reservationId: reservation.id,
        }
      );
    }
  }, [reservation.guest.id]);

  const cancelWarning = async (text: string) => {
    onReservationAction(
      reservation.id,
      ReservationActionTypes.CANCEL_WARNING,
      text
    );
  };

  return (
    <Box
      className="reservation-side-bar"
      id="details-container"
      style={
        ['failed', 'done'].includes(reservation?.currentStatus || '')
          ? { maxHeight: 'calc(100% - 50px)' }
          : { maxHeight: 'calc(100% - 150px)' }
      }
      onScroll={(e) => handleScrolling(e)}
    >
      <Warning id={reservation.id} />
      {!!reservation.ticketId && (
        <TicketInformationV02 reservation={reservation} />
      )}
      {!!reservation.minimumConsumption && (
        <MinimumConsumption reservation={reservation} />
      )}

      <AddOnInformations id={reservation.id} />

      {reservation.customData && (
        <>
          {Object.entries(reservation.customData).map(([key, value]) => (
            <Box className="space-between reservation-field" key={key}>
              <Typography variant="text-3" block style={{ marginBottom: 8 }}>
                {key === 'balance'
                  ? 'Bisher bestellt'
                  : key.replace('_internal', '')}
              </Typography>
              <Typography variant="text-2" color="subdued" textAlign="right">
                {stringifyCustomDataValue(value, key)}
              </Typography>
            </Box>
          ))}
        </>
      )}

      {experimentalFlags.data?.editGuestComment ? (
        <CommentModal
          label="Guest Comment"
          value={reservation.guest.comment || ''}
          onChange={(value) =>
            onReservationAction(
              reservation.id,
              ReservationActionTypes.COMMENT,
              value
            )
          }
          id={reservation.id}
          action="comment"
          editableField
          labelTranslation="reservations"
        />
      ) : (
        <Box className="reservation-field">
          <Typography
            variant="text-3"
            translation="reservations"
            block
            style={{ marginBottom: 8 }}
          >
            Guest Comment
          </Typography>
          <Typography
            variant="text-2"
            color="subdued"
            translation="reservations"
          >
            {reservation.guest.comment || '-'}
          </Typography>
        </Box>
      )}
      <CommentModal
        label="Waiter Comment"
        value={reservation.guest.hostComment || ''}
        onChange={(value) =>
          onReservationAction(
            reservation.id,
            ReservationActionTypes.HOST_COMMENT,
            value
          )
        }
        id={reservation.id}
        action="hostComment"
        editableField
        labelTranslation="reservations"
      />
      {!!(reservation.guest.id || reservation.guest?.guestId) && (
        <CommentModal
          label="Langzeit Kommentar"
          value={reservation.guest.guestComment || ''}
          onChange={(value) =>
            onReservationAction(
              reservation.id,
              ReservationActionTypes.GUEST_COMMENT,
              {
                guestComment: value,
                relationshipId:
                  reservation.guest.id || reservation.guest?.guestId,
              }
            )
          }
          id={reservation.id}
          action="guestComment"
          editableField
          labelTranslation="reservations"
        />
      )}
      {reservation.additional && (
        <TicketInformation {...reservation.additional} />
      )}
      {reservation.experienceVoucher && (
        <ExperienceVoucher {...reservation.experienceVoucher} />
      )}

      {detailsFields.map((f, i) => (
        <Box
          key={i}
          className={`space-between reservation-field ${
            String(f?.value ?? '').length > 30 ? 'flex-start' : ''
          }`}
        >
          <Typography variant="text-3" translation="reservations">
            {f.label}
          </Typography>
          <Typography
            variant={String(f?.value ?? '').length > 20 ? 'text-3' : 'text-2'}
            color="subdued"
            translation={f.label === 'Status' ? 'reservations' : null}
            textAlign={f.align as any}
          >
            {f.value}
          </Typography>
        </Box>
      ))}

      {!!reservation.noShowFee && <NoShowFee {...reservation.noShowFee} />}
      <Box className="reservation-field">
        <Typography
          variant="text-3"
          translation="reservations"
          block
          style={{ marginBottom: 8 }}
        >
          Attributes
        </Typography>
        <AttributeDropdown
          value={reservation.guest.attr || []}
          onChange={(newValue) =>
            onReservationAction(
              reservation.id,
              ReservationActionTypes.ATTR,
              newValue
            )
          }
        />
      </Box>

      <Box className="reservation-field" flex>
        <Checkbox
          label=""
          checked={reservation.excludeFromSlots}
          onChange={(e) =>
            onReservationAction(
              reservation.id,
              ReservationActionTypes.EXCLUDE_FROM_SLOTS,
              e.target.checked
            )
          }
          color="primary"
          className="reservation-checkbox"
        />
        <Typography variant="text-3" translation="reservations">
          Exclude from reservation slots
        </Typography>
      </Box>
      <RegularTableButton reservation={reservation} />
    </Box>
  );
};

export default ReservationDetails;

const AddOnInformations: React.FC<{ id: string }> = ({ id }) => {
  const [orders] = useCollection<AddOnOrder>('addOnOrders', {
    filter: [['reservationId', '==', id]],
  });

  const filteredOrders = orders.data.filter(
    (x) => x.status === 'succedded' || x.status === 'processing'
  );

  const total = filteredOrders.reduce(
    (acc, cur) => acc + cur.total - (cur.refundedAmount ?? 0),
    0
  );

  const addOns = filteredOrders.reduce((acc, cur) => {
    return [...acc, ...cur.cart.map((x) => ({ ...x, status: cur.status }))];
  }, [] as (CartItem & { status: 'missing_payments' | 'processing' | 'failed' | 'succedded' })[]);

  if (!filteredOrders || filteredOrders.length === 0) return <></>;

  return (
    <div>
      <Box style={{ padding: '16px 16px 0 16px' }}>
        <Typography
          className="colon"
          variant="text-3"
          translation="reservations"
        >
          Add-Ons
        </Typography>
      </Box>
      <Box className="space-between reservation-field no-border">
        <Typography variant="text-3" translation="reservations">
          Price paid
        </Typography>
        <Typography variant="text-2" color="subdued" translation={null}>
          {toCurrencyString(total * 100)}
        </Typography>
      </Box>
      {addOns.map((o) => (
        <Box
          key={o.id}
          className={classHelper([
            'reservation-field',
            (o.type === 'simple' || o.type === 'table_select') &&
              'space-between',
          ])}
        >
          <Typography
            variant="text-3"
            translation={null}
            className={o.refunded ? 'line-through' : ''}
            color={o.status === 'failed' ? 'error' : 'textPrimary'}
          >
            {o.title}
          </Typography>
          {o.type === 'simple' && (
            <Typography
              variant="text-2"
              color="subdued"
              className={o.refunded ? 'line-through' : ''}
              translation={null}
            >
              {toCurrencyString(o.total * 100)}
            </Typography>
          )}
          {o.type === 'select' && (
            <Box
              className={
                o.refunded ? 'space-between line-through' : 'space-between'
              }
            >
              <Typography variant="text-2" color="subdued" translation={null}>
                {o.choice.title}
              </Typography>
              <Typography variant="text-2" color="subdued" translation={null}>
                {toCurrencyString(o.total * 100)}
              </Typography>
            </Box>
          )}
          {o.type === 'multiple_select' && (
            <Box className={o.refunded ? 'line-through' : ''}>
              {o.choices.map((c) =>
                c.count ? (
                  <Box key={c.id} className="space-between">
                    <Typography
                      variant="text-2"
                      color="subdued"
                      translation={null}
                    >
                      {c.count}x {c.title}
                    </Typography>
                    <Typography
                      variant="text-2"
                      color="subdued"
                      translation={null}
                    >
                      {toCurrencyString(c.price.amount * c.count * 100)}
                    </Typography>
                  </Box>
                ) : (
                  <React.Fragment key={c.id}></React.Fragment>
                )
              )}
            </Box>
          )}
          {!!o.internalNote && (
            <Typography
              variant="text-4"
              translation={null}
              className={o.refunded ? 'line-through' : ''}
              color={o.status === 'failed' ? 'error' : 'subdued'}
            >
              {o.internalNote}
            </Typography>
          )}
        </Box>
      ))}
    </div>
  );
};
