import { CircularProgress, IconButton } from '@material-ui/core';
import { Lock, LockOpen, PartlyLocked } from 'Components/Atoms/Icons';
import Box from 'Components/Atoms/Box';
import Select from 'Components/Atoms/Select';
import Typography from 'Components/Atoms/Typography';
import { ReservationContext } from 'Contexts/ReservationContext';
import React, { useContext, useMemo } from 'react';
import { Space } from 'types/reservations';
import server from 'utils/server';
import { RestaurantContext, Severity } from 'Contexts/RestaurantContext';
import { ReservationShift } from 'gastronaut-shared/types/helper/shifts';
import usePinAuth from 'CustomHooks/usePinAuth';
import useToast from 'CustomHooks/useToast';

type Props = {
  value: string | null;
  onChange: (nV: string) => void;
  spaces: Space[];
  title?: string;
  menuClassName?: string;
};

const SpaceSwitch: React.FC<Props> = ({
  value,
  onChange,
  spaces,
  title = 'Space',
  menuClassName = 'spaceSwitchMenu',
}) => {
  const options = spaces.map((s) => ({ id: s.id, label: s.name }));

  const { restaurantId } = useContext(RestaurantContext);

  const { shifts, floorPlanProps, date } = useContext(ReservationContext);

  const closed = useMemo(() => {
    let openShifts = shifts?.filter((s) => s.active && !s.commentOnly) ?? [];

    let whereSpaceIsClosed = openShifts.filter(
      (s) =>
        !value ||
        s.closed ||
        (s.spaces.length && !s.spaces.includes(value) && s.matchBoth) ||
        (s.overwrite as any)?.closedSpaces?.includes(value) ||
        !!s.overwrite?.blockReservations
    );

    console.log({
      openShifts: openShifts.length,
      whereSpaceIsClosed: whereSpaceIsClosed.length,
    });

    if (
      whereSpaceIsClosed.length &&
      whereSpaceIsClosed.length === openShifts.length
    )
      return 2;
    if (!whereSpaceIsClosed.length) return 0;
    return 1;
  }, [shifts, value]);

  const [loading, setLoading] = React.useState(false);

  const { authenticate } = usePinAuth();
  const toast = useToast();

  const toggleSpace = async () => {
    let openShifts = shifts?.filter((s) => s.active && !s.commentOnly) ?? [];

    let whereSpaceIsClosed = openShifts.filter(
      (s) =>
        !value ||
        s.closed ||
        (s.spaces.length && !s.spaces.includes(value) && s.matchBoth) ||
        !!s.overwrite?.blockReservations
    );

    if (
      whereSpaceIsClosed.length === openShifts.length ||
      openShifts.length === 0
    ) {
      return toast(
        'Bereich ist durch andere Regeln geschlossen.',
        Severity.ERROR
      );
    }

    try {
      if (loading || !restaurantId || !date) return;
      setLoading(true);
      const { data } = await server.post<{ shifts: ReservationShift[] }>(
        `/v03/shifts/${restaurantId}/${date}/closeSpace`,
        {
          spaceId: value,
          closed: closed !== 2,
        }
      );

      floorPlanProps.setShifts(data.shifts);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Box className="spaceSwitch" width={200}>
      <Typography
        translation="reservations"
        variant="text-4"
        className="mg-bt-xs"
        style={{ maxWidth: 170 }}
      >
        {title}
      </Typography>
      <Box flex width={170}>
        <Select
          value={value}
          options={options}
          menuClassName={menuClassName}
          onChange={(e: any) => onChange(e.target.value)}
        />
        <IconButton
          onClick={() =>
            authenticate('reservations.spaceBlocked', () => toggleSpace())
          }
        >
          {loading && <CircularProgress size={24} />}
          {!loading && closed === 2 && <Lock color="error" />}
          {!loading && closed === 1 && <PartlyLocked color="primary" />}
          {!loading && closed === 0 && <LockOpen color="primary" />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default SpaceSwitch;
