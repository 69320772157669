import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { CurrentStatus, Reservation } from 'types/reservations';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import GTable, {
  ContextAction,
  ListConfigHeader,
} from 'Components/Organisms/GTable';
import Typography from 'Components/Atoms/Typography';
import {
  blockToTime,
  dateHelper,
  timeToBlock,
  toEuropeanDate,
} from 'utils/helper';
import AttrIcons from '../ReservationCard/Components/AttrIcons';
import {
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  PermPhoneMsgRounded,
  GetApp,
} from '@material-ui/icons';
import { Seat, TableSwitch } from 'Components/Atoms/Icons';
import { reservations } from 'App/ReservationBook/TestData';
import {
  CustomDataValue,
  WaitingListEntry,
} from 'gastronaut-shared/types/helper/reservations';
import Divider from 'Components/Atoms/Divider';
import Button from 'Components/Atoms/Button';
import { useTranslation } from 'react-i18next';

export type ReservationTableProps = {
  reservations: Reservation[];
  groups?: { id: string; title: string }[];
  occassions: { id: string; title: string }[];
  spaces: { id: string; name: string }[];
  active: string | null;
  warnings: string[];
  hideOccassion?: boolean;
  onRowClick: (id: string) => void;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  fields?: string[];
  groupBy?: string;
  hideEmptyTables?: boolean;
  search?: boolean;
  mode?: 'addons' | 'tickets' | 'normal';
  sidebarOpen?: boolean;
};

function stringifyCustomDataValue(value: CustomDataValue | undefined) {
  if (!value) return '';
  if (typeof value === 'boolean') {
    return value ? 'Wahr' : 'Falsch';
  } else if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return value;
  }
}

const ReservationTable: React.FC<ReservationTableProps> = (props) => {
  const { addOnOrders, customFields } = useContext(ReservationContext);
  const { t } = useTranslation('reservations');

  const ticketFields = useMemo(() => {
    if (!props.reservations?.length) return [];
    let fieldIds: string[] = [];

    for (const reservation of props.reservations) {
      for (const option in reservation.ticketData ?? {}) {
        if (!fieldIds.includes(option) && !!reservation?.ticketData?.[option]) {
          fieldIds.push(option);
        }
      }
    }

    return fieldIds.sort((a, b) => {
      let A = a.startsWith('o_') ? 0 : 1;
      let B = b.startsWith('o_') ? 0 : 1;

      return A - B;
    });
  }, [props.reservations]);

  const addOnFields = useMemo(() => {
    if (!props.reservations?.length) return [];
    let fieldIds: string[] = [];

    for (const reservation of props.reservations) {
      for (const option in reservation.addOnData ?? {}) {
        if (!fieldIds.includes(option) && !!reservation?.addOnData?.[option]) {
          fieldIds.push(option);
        }
      }
    }

    return fieldIds.sort((a, b) => {
      return a.localeCompare(b);
    });
  }, [props.reservations]);

  const contextActions: ContextAction<Reservation>[] = [
    {
      icon: <TableSwitch color="inherit" />,
      iconColor: 'inherit',
      onClick: (reservation) => {
        if (reservation.fixed) {
          alert({
            title: 'Reservierung is fixiert',
            description:
              'Diese Reservierung ist fixiert willst du Sie trotzdem umplatzieren?',
            onSubmit: () =>
              props.handleAction(
                reservation.id,
                ReservationActionTypes.RELOCATE
              ),
          });
        } else {
          props.handleAction(reservation.id, ReservationActionTypes.RELOCATE);
        }
      },
      tooltipContent: 'Relocate',
      tooltipContentTranslation: 'reservations',
      showCondition: (reservation) =>
        !(
          reservation.status === 'pending' ||
          reservation.status === 'positive' ||
          reservation.currentStatus === CurrentStatus.FAILED
        ),
    },
    {
      icon: <CancelOutlined color="inherit" />,
      iconColor: 'inherit',
      onClick: (data) =>
        props.handleAction(data.id, ReservationActionTypes.REFUSE),
      tooltipContent: 'Cancel',
      tooltipContentTranslation: 'reservations',
      showCondition: (reservation) =>
        reservation.status === 'pending' || reservation.status === 'positive',
    },
    {
      icon: <CheckCircle color="inherit" />,
      iconColor: 'inherit',
      onClick: (data) =>
        props.handleAction(data.id, ReservationActionTypes.MARK_AS_DONE),
      tooltipContent: 'Mark as done',
      tooltipContentTranslation: 'reservations',
      showCondition: (reservation) =>
        reservation.currentStatus === 'seated' ||
        reservation.currentStatus === 'paid' ||
        reservation.currentStatus === 'hasOrdered',
    },
    {
      icon: <Seat color="inherit" />,
      iconColor: 'inherit',
      onClick: (data) =>
        props.handleAction(data.id, ReservationActionTypes.SEAT),
      tooltipContent: 'Seat',
      tooltipContentTranslation: 'reservations',
      showCondition: (reservation) =>
        !(
          reservation.currentStatus === 'seated' ||
          reservation.currentStatus === 'paid' ||
          reservation.currentStatus === 'hasOrdered' ||
          reservation.currentStatus === CurrentStatus.FAILED
        ) && reservation.date === dateHelper(),
    },
  ];

  const groups = useMemo(() => {
    const groups = (props.groups ?? [{ id: 'all', title: 'All' }])
      .filter((g) => g.id)
      .map((g) => ({
        id: g.id,
        title: g.title,
        reservations: props.reservations.filter(
          (r) => g.id === 'all' || r.group?.includes(g.id)
        ),
      }))
      .filter(
        (x) =>
          !props.hideEmptyTables || !!x.reservations.length || x.id === 'all'
      );

    if (!groups.length) {
      return [
        {
          id: 'all',
          title: 'All',
          reservations: props.reservations,
        },
      ];
    }

    if (groups[0]?.id === 'all') return groups;

    let other = props.reservations.filter((x) => x.group === '__other__');

    if (other.length) {
      groups.push({
        id: '__other__',
        title: 'Andere',
        reservations: other,
      });
    }

    return groups;
  }, [props.groups, props.hideEmptyTables, props.reservations]);

  const headers = useMemo(() => {
    let mode = props.mode ?? 'normal';

    let headers: ListConfigHeader<Reservation>[] = [];

    let fields: string[];

    if (mode === 'tickets') {
      headers = [
        {
          field: 'name',
          headerName: 'Name',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 200,
          style: {
            position: 'sticky',
            left: 0,
            zIndex: 2,
          },
          summaryFn: (x) => `${x.length} Reservierungen`,
          displayFunction: (data) => (
            <Typography
              variant="text-3"
              color="subdued"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              {!!data.experienceVoucher && '🎁 '}
              <span
                style={
                  data.hasPayment
                    ? {
                        background: 'var(--color-secondary)',
                        color: 'white',
                        padding: '0 4px',
                        borderRadius: 2,
                      }
                    : {}
                }
              >
                {data.guest.name}
                {data.guest.company ? ` (${data.guest.company})` : ''}
              </span>
              {data.source === 'phone' && (
                <PermPhoneMsgRounded
                  fontSize="inherit"
                  style={{ marginLeft: 4 }}
                />
              )}
              <AttrIcons
                customAttr={data.guest.customAttr ?? []}
                attr={[
                  ...(data.guest?.attr || []),
                  data.guest?.comment || data.guest?.hostComment
                    ? 'comment'
                    : '',
                  !!data.ticketId ? 'ticket' : '',
                  !!data.minimumConsumption ? 'ticket' : '',
                ]}
                fixed={data.fixed}
                coloredLogo={true}
                // feedbackRating={feedbackRating}
                done={!!data.done}
                creditCardCustomerId={data.guest.creditCardCustomerId}
                noShow={!!data.noShow}
                noShowFee={data.noShowFee}
                addOns={!!data.hasAddOnOrders}
              />
            </Typography>
          ),
        },
        {
          field: 'guests',
          headerName: 'PAX',
          headerTranslation: 'reservations',
          sortableBy: true,
          maxWidth: 85,
          summaryFn: (x) => `${x.reduce((acc, cV) => acc + cV, 0)} PAX`,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.guests}
            </Typography>
          ),
          displayString: (data) => data.guests.toString(),
        },
        {
          field: 'time',
          headerName: 'Time',
          headerTranslation: 'reservations',
          sortableBy: true,
          maxWidth: 85,
          displayFunction: (data) => (
            <Box>
              <Typography
                variant="text-3"
                // color="subdued"
                block
                style={{ whiteSpace: 'nowrap' }}
              >
                {data.time} -
              </Typography>
              <Typography
                variant="text-3"
                color="subdued"
                style={{ whiteSpace: 'nowrap' }}
              >
                {blockToTime(data.endTimeInBlocks)}
              </Typography>
            </Box>
          ),
          displayString: (data) =>
            `${data.time} - ${blockToTime(data.endTimeInBlocks)}`,
        },
        {
          field: 'tables',
          headerName: 'Tables',
          maxWidth: 100,
          headerTranslation: 'reservations',
          sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.tables?.join(', ') ?? ''}
            </Typography>
          ),
          displayString: (data) => data.tables?.join(', ') ?? '',
        },
        {
          field: 'occassion',
          headerName: 'Occassion',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 120,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {props.occassions.find((x) => x.id === data.occassion)?.title ??
                data.occassion}
            </Typography>
          ),
          displayString: (data) =>
            props.occassions.find((x) => x.id === data.occassion)?.title ??
            data.occassion,
        },
      ];

      for (const t of ticketFields) {
        headers.push({
          field: `ticketData.${t}`,
          headerName: t.split('_')[1],
          headerTranslation: 'reservations',
          sortableBy: true,
          summaryFn: 'sum',
          // maxWidth: 100,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data?.ticketData?.[t] ?? 0}
            </Typography>
          ),
          displayString: (data) => String(data?.ticketData?.[t] ?? 0),
        });
      }

      // headers.push({
      //   field: 'allComments',
      //   headerName: 'Comment',
      //   headerTranslation: 'reservations',
      //   sortableBy: false,
      //   displayFunction: (data) => (
      //     <Box>
      //       {data.guest.comment && (
      //         <Typography variant="text-3" color="subdued" block>
      //           <strong>Gast:</strong> {data.guest.comment}
      //         </Typography>
      //       )}
      //       {data.guest.hostComment &&
      //         !data.guest.guestComment?.includes(data.guest.hostComment) && (
      //           <Typography variant="text-3" color="subdued" block>
      //             <strong>Service:</strong> {data.guest.hostComment}
      //           </Typography>
      //         )}
      //       {data.guest.guestComment && (
      //         <Typography variant="text-3" color="subdued" block>
      //           <strong>Langzeit:</strong> {data.guest.guestComment}
      //         </Typography>
      //       )}
      //     </Box>
      //   ),
      // });
    } else {
      fields = props.fields || [
        // 'id',
        'name',
        props.hideOccassion || props.groupBy == 'occassion' ? '' : 'occassion',
        'guests',
        'time',
        'tables',
        props.spaces.length > 1 && props.groupBy !== 'space' ? 'space' : '',
        addOnOrders.length ? 'addOnItems' : '',
        'allComments',
        //   'comment',
        //   'hostComment',
      ];

      if (props.search) {
        // add date to fields in 2 position
        fields.splice(2, 0, 'date');
      }

      headers = [
        {
          field: 'id',
          headerName: 'Code',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 90,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              #{data.customId}
            </Typography>
          ),
          displayString: (data) => '#' + String(data.customId),
        },
        {
          field: 'addOnItems',
          headerName: 'Add Ons',
          headerTranslation: 'reservations',
          sortableBy: false,
          width: 200,
          summaryFn: (x) => `${x.flat(2).length} Add Ons`,
          displayFunction: (data) => (
            <Box>
              {data.addOnItems?.map((item, i, arr) => (
                <Box
                  key={item.id}
                  style={{
                    border: '1px solid var(--color-border)',
                    borderRadius: 4,
                    padding: '2px 4px',
                    marginBottom: arr.length - 1 > i ? 4 : 0,
                    display: 'inline-block',
                    textDecoration: item.refunded ? 'line-through' : 'none',
                  }}
                >
                  <Typography
                    variant="text-3"
                    color="subdued"
                    block
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {item.line1}
                  </Typography>
                  <Typography variant="text-4" color="subdued" block>
                    {item.line2}
                  </Typography>
                </Box>
              ))}
            </Box>
          ),
          displayString: (data) =>
            data.addOnItems
              ?.map((item) => `${item.line1} ${item.line2}`)
              .join(', ') ?? '',
        },
        {
          field: 'name',
          headerName: 'Name',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 200,
          summaryFn: (x) => `${x.length} Reservierungen`,
          displayFunction: (data) => (
            <Typography
              variant="text-3"
              color="subdued"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              {!!data.experienceVoucher && '🎁 '}
              <span
                style={
                  data.hasPayment
                    ? {
                        background: 'var(--color-secondary)',
                        color: 'white',
                        padding: '0 4px',
                        borderRadius: 2,
                      }
                    : {}
                }
              >
                {data.guest.name}
                {data.guest.company ? ` (${data.guest.company})` : ''}
              </span>
              {data.source === 'phone' && (
                <PermPhoneMsgRounded
                  fontSize="inherit"
                  style={{ marginLeft: 4 }}
                />
              )}
              <AttrIcons
                customAttr={data.guest.customAttr ?? []}
                attr={[
                  ...(data.guest?.attr || []),
                  data.guest?.comment || data.guest?.hostComment
                    ? 'comment'
                    : '',
                  !!data.ticketId ? 'ticket' : '',
                  !!data.minimumConsumption ? 'ticket' : '',
                ]}
                fixed={data.fixed}
                coloredLogo={true}
                // feedbackRating={feedbackRating}
                done={!!data.done}
                creditCardCustomerId={data.guest.creditCardCustomerId}
                noShow={!!data.noShow}
                noShowFee={data.noShowFee}
                addOns={!!data.hasAddOnOrders}
                emailFailed={data.emailStatus === 'failed'}
              />
            </Typography>
          ),
          displayString: (data) =>
            `{!!data.experienceVoucher && '🎁 '} ${data.guest.name}${
              data.guest.company ? ` (${data.guest.company})` : ''
            }`,
        },
        {
          field: 'phone',
          headerName: 'Phone',
          headerTranslation: 'reservations',
          width: 120,
          // sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.guest.phone &&
              !data.guest.phone.startsWith('+') &&
              !data.guest.phone.startsWith('0')
                ? '+'
                : ''}
              {data.guest.phone}
            </Typography>
          ),
          displayString: (data) => `${
            data.guest.phone &&
            !data.guest.phone.startsWith('+') &&
            !data.guest.phone.startsWith('0')
              ? '+'
              : ''
          }
              ${data.guest.phone}`,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 120,
          headerTranslation: 'reservations',
          // sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.guest.email}
            </Typography>
          ),
          displayString: (data) => data.guest.email,
        },
        {
          field: 'guests',
          headerName: 'PAX',
          headerTranslation: 'reservations',
          sortableBy: true,
          maxWidth: 85,
          summaryFn: (x) => `${x.reduce((acc, cV) => acc + cV, 0)} PAX`,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.guests}
            </Typography>
          ),
          displayString: (data) => data.guests.toString(),
        },
        {
          field: 'time',
          headerName: 'Time',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 85,
          displayFunction: (data) => (
            <Box>
              <Typography
                variant="text-3"
                // color="subdued"
                block
                style={{ whiteSpace: 'nowrap' }}
              >
                {data.time} -
              </Typography>
              <Typography
                variant="text-3"
                color="subdued"
                block
                style={{ whiteSpace: 'nowrap' }}
              >
                {blockToTime(data.endTimeInBlocks)}
              </Typography>
            </Box>
          ),
          displayString: (data) =>
            `${data.time} - ${blockToTime(data.endTimeInBlocks)}`,
        },
        {
          field: 'tables',
          headerName: 'Tables',
          width: 100,
          headerTranslation: 'reservations',
          sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.tables?.join(', ') ?? ''}
            </Typography>
          ),
          displayString: (data) => data.tables?.join(', ') ?? '',
        },
        {
          field: 'space',
          headerName: 'Space',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 120,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {props.spaces.find((x) => x.id === data.space)?.name ?? ''}
            </Typography>
          ),
          displayString: (data) =>
            props.spaces.find((x) => x.id === data.space)?.name ?? '',
        },
        {
          field: 'occassion',
          headerName: 'Occassion',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 120,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {props.occassions.find((x) => x.id === data.occassion)?.title ??
                data.occassion}
            </Typography>
          ),
          displayString: (data) =>
            props.occassions.find((x) => x.id === data.occassion)?.title ??
            data.occassion,
        },
        {
          field: 'comment',
          headerName: 'Comment',
          headerTranslation: 'reservations',
          sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {data.guest.comment}
            </Typography>
          ),
          displayString: (data) => data.guest.comment ?? '',
        },
        {
          field: 'hostComment',
          headerName: 'Host Comment',
          headerTranslation: 'reservations',
          sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {[data.guest.hostComment, data.guest.guestComment]
                .filter(Boolean)
                .join(' - ')}
            </Typography>
          ),
          displayString: (data) =>
            `${data.guest.hostComment} - ${data.guest.guestComment}`,
        },
        {
          field: 'allComments',
          headerName: 'Comment',
          headerTranslation: 'reservations',
          sortableBy: false,
          width: addOnFields.length && mode === 'addons' ? 200 : undefined,
          displayFunction: (data) => (
            <Box>
              {data.guest.comment && (
                <Typography variant="text-3" color="subdued" block>
                  <strong>Gast:</strong> {data.guest.comment}
                </Typography>
              )}
              {data.guest.hostComment &&
                !data.guest.guestComment?.includes(data.guest.hostComment) && (
                  <Typography variant="text-3" color="subdued" block>
                    <strong>Service:</strong> {data.guest.hostComment}
                  </Typography>
                )}
              {data.guest.guestComment && (
                <Typography variant="text-3" color="subdued" block>
                  <strong>Langzeit:</strong> {data.guest.guestComment}
                </Typography>
              )}
            </Box>
          ),
          displayString: (data) =>
            `${data.guest.comment} - ${data.guest.hostComment} - ${data.guest.guestComment}`,
        },
        {
          field: 'date',
          headerName: 'Date',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 120,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {toEuropeanDate(data.date)}
            </Typography>
          ),
          displayString: (data) => toEuropeanDate(data.date),
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          headerTranslation: 'reservations',
          sortableBy: true,
          width: 120,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {toEuropeanDate(dateHelper(data.createdAt))}
            </Typography>
          ),
          displayString: (data) => toEuropeanDate(dateHelper(data.createdAt)),
        },
      ];

      headers = headers
        .filter((x) => fields.includes(x.field))
        .sort((a, b) => fields.indexOf(a.field) - fields.indexOf(b.field));

      if (mode === 'addons') {
        for (const t of addOnFields) {
          headers.push({
            field: `addOnData.${t}`,
            headerName: t.split('  ').join('\n'),
            displayHeader: () => (
              <Typography variant="text-4">
                {t.split('  ')[0]}
                <br />
                <small>{t.split('  ')[1]}</small>
              </Typography>
            ),
            headerTranslation: 'reservations',
            sortableBy: true,
            summaryFn: 'sum',
            maxWidth: t.split('  ')[0].length * 8,
            displayFunction: (data) => (
              <Typography variant="text-3" color="subdued">
                {data?.addOnData?.[t] ?? 0}
              </Typography>
            ),
            displayString: (data) => String(data?.addOnData?.[t] ?? 0),
          });
        }
      }
    }

    if (customFields) {
      customFields.forEach((field) => {
        if (!!fields && !fields.includes(field)) return;
        headers.push({
          field: field,
          headerName: field,
          headerTranslation: 'reservations',
          sortableBy: true,
          displayFunction: (data) => (
            <Typography variant="text-3" color="subdued">
              {stringifyCustomDataValue(data.customData?.[field])}
            </Typography>
          ),
          displayString: (data) =>
            String(stringifyCustomDataValue(data.customData?.[field])),
        });
      });
    }

    return headers;
  }, [
    props.fields,
    props.occassions,
    props.spaces,
    props.groupBy,
    groups,
    props.mode,
    props.search,
    addOnOrders,
    ticketFields,
    addOnFields,
  ]);

  const download = () => {
    // Get all visible headers based on current fields
    const visibleHeaders = headers;

    const HEADERS = visibleHeaders.map((header) => {
      if (header.headerTranslation) {
        const headerName =
          typeof header.headerName === 'function'
            ? header.headerName()
            : header.headerName;
        return t(headerName);
      }
      return typeof header.headerName === 'function'
        ? header.headerName()
        : header.headerName;
    });

    // Create CSV header row
    const csvRows: (string | (() => string))[][] = [];

    groups.forEach((group, i, arr) => {
      if (arr.length > 1) {
        csvRows.push([
          `"**${group.title}**"`,
          ...Array.from({ length: HEADERS.length - 1 }, () => '""'),
        ]);
      }
      csvRows.push(HEADERS);
      group.reservations.forEach((reservation, i) => {
        const row = visibleHeaders.map((header) => {
          const value = header.displayString
            ? header.displayString(reservation, i)
            : (reservation[header.field as keyof Reservation] as string);

          // Handle special cases
          if (header.field === 'name') {
            return `${reservation.guest.name}${
              reservation.guest.company ? ` (${reservation.guest.company})` : ''
            }`;
          }
          if (header.field === 'time') {
            return `${reservation.time} - ${blockToTime(
              reservation.endTimeInBlocks
            )}`;
          }
          if (header.field === 'tables') {
            return reservation.tables?.join(', ') ?? '';
          }
          if (header.field === 'space') {
            return (
              props.spaces.find((x) => x.id === reservation.space)?.name ?? ''
            );
          }
          if (header.field === 'occassion') {
            return (
              props.occassions.find((x) => x.id === reservation.occassion)
                ?.title ?? reservation.occassion
            );
          }
          if (header.field === 'date') {
            return toEuropeanDate(reservation.date);
          }
          if (header.field === 'createdAt') {
            return toEuropeanDate(dateHelper(reservation.createdAt));
          }
          if (header.field === 'allComments') {
            const comments = [];
            if (reservation.guest.comment)
              comments.push(`Gast: ${reservation.guest.comment}`);
            if (
              reservation.guest.hostComment &&
              !reservation.guest.guestComment?.includes(
                reservation.guest.hostComment
              )
            ) {
              comments.push(`Service: ${reservation.guest.hostComment}`);
            }
            if (reservation.guest.guestComment)
              comments.push(`Langzeit: ${reservation.guest.guestComment}`);
            return comments.join(' | ');
          }
          if (header.field.startsWith('ticketData.')) {
            return reservation.ticketData?.[header.field.split('.')[1]] ?? 0;
          }
          if (header.field.startsWith('addOnData.')) {
            return reservation.addOnData?.[header.field.split('.')[1]] ?? 0;
          }
          if (customFields?.includes(header.field)) {
            return stringifyCustomDataValue(
              reservation.customData?.[header.field]
            );
          }
          return value;
        });
        csvRows.push(row.map((c) => `"${c}"`.replace(/\"{2}/g, '"')));
      });
      if (i < arr.length - 1) csvRows.push(['""']);
    });

    // Convert to CSV string
    const csvContent = csvRows
      .map((row) =>
        row.map((cell) => `"${String(cell)}"`.replace(/\"{2}/g, '"')).join(',')
      )
      .join('\n');

    if (window.confirm('Als PDF herunterladen?')) {
      // Create PDF
      fetch('https://reservation-table.deno.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/csv',
        },
        body: csvContent,
      })
        .then((response) => {
          if (!response.ok) {
            console.log({ csvContent });
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          // link.download = `reservations_${dateHelper(new Date())}.pdf`;
          document.body.appendChild(link);
          link.click();
          // document.body.removeChild(link);
          // window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error creating PDF:', error);
          alert('Error creating PDF. Please try again.');
        });

      return;
    }

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `reservations_${dateHelper(new Date())}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      style={{
        overflow: 'scroll',
        maxWidth: props.sidebarOpen
          ? 'calc(100vw - 64px - var(--sidebar-width))'
          : 'calc(100vw - 64px)',
      }}
    >
      <Button
        onClick={download}
        variant="primary"
        color="primary"
        style={{
          position: 'fixed',
          bottom: '24px',
          right: '24px',
          borderRadius: '50%',
          width: '56px',
          height: '56px',
          minWidth: '56px',
          padding: 0,
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          zIndex: 1000,
        }}
      >
        <GetApp />
      </Button>
      {groups.map((group, i) => (
        <Box key={group.id}>
          {group.id !== 'all' && (
            <Typography
              variant="h4"
              color="textPrimary"
              block
              className="mg-bt-sm"
              style={!i ? { marginTop: 8 } : {}}
            >
              {group.title}
            </Typography>
          )}{' '}
          <GTable
            headers={headers}
            data={group.reservations}
            contextActions={contextActions}
            onRowClick={props.onRowClick}
            sortable
            summaryTop
            styleFunction={(reservation: Reservation) => {
              let backgroundColor = 'var(--color-paper)';

              if (reservation.currentStatus === CurrentStatus.SEATED) {
                backgroundColor = 'rgba(225, 14, 88, 0.5)';
              } else if (reservation.currentStatus === CurrentStatus.PAID) {
                backgroundColor = 'rgba(85, 0, 169, 0.5)'; // '#5500a9'
              } else if (
                reservation.currentStatus === CurrentStatus.HAS_ORDERED
              ) {
                backgroundColor = 'rgba(177, 11, 69, 0.5)'; // '#b10b45'
              } else if (reservation.currentStatus === CurrentStatus.UPCOMING) {
                backgroundColor = 'rgba(13, 175, 162, 0.5)'; // '#0dafa2'
              } else if (reservation.currentStatus === CurrentStatus.OVERDUE) {
                backgroundColor = 'rgba(246, 194, 90, 0.5)'; // '#f6c25a'
              }

              return {
                backgroundColor,
              };
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ReservationTable;
