import { ReservationStatus } from 'types/reservations';
import { Date, ReservationShift } from 'types/shifts';

export type Size = number | 'sm' | 'md' | 'lg';

export type Translations =
  | null
  | 'common'
  | 'auth'
  | 'dashboard'
  | 'errors'
  | 'products'
  | 'takeAway'
  | 'allergens'
  | 'meals'
  | 'feedback'
  | 'reservations'
  | 'settings'
  | 'healthcheck'
  | 'vouchers'
  | 'website'
  | 'onboarding'
  | 'calendar'
  | 'tickets'
  | 'experiences';

export type Products =
  | 'general'
  | 'delivery'
  | 'vouchers'
  | 'merchandise'
  | 'followUp'
  | 'menu'
  | 'feedback'
  | 'reservation'
  | 'light-reservation'
  | 'website'
  | 'linktree'
  | 'guest-ordering'
  | 'platform'
  | 'sia-settings'
  | 'phone'
  | 'faq'
  | 'addOns'
  | 'newsletter'
  | 'sia-phone-assistant';

export type Modify<T, R> = Omit<T, keyof R> & R;

export type Translation<t> = Record<string, t>;

export enum UserRoles {
  WAITER = 'waiter',
  CHEFWAITER = 'chefWaiter',
  TABLET = 'tablet',
  ADMIN = 'admin',
}

export type User = {
  avatar: string | null;
  restaurants: any[];
  role: UserRoles;
};

export interface UserData {
  displayName: string | null;
  isGastronautAdmin?: boolean;
  email: string | null;
  emailVerified: boolean;
  photoURL: string | null;
  isAnonymous: boolean;
  uid: string;
  role: UserRoles;
  restaurants: Restaurant[];
  provider?: string;
  appVersion?: string;
}

export type Restaurant = {
  id: string;
  name?: string;
  admin: boolean;
  role?: string;
  title?: string;
  products: Products[];
};

export type Weather = {
  app_max_temp: number | null;
  code: any;
  description: string | null;
  icon: string | null;
};

export interface Day extends Date {
  reservations: number;
  average: number;
  open: boolean;
  showTerrace: boolean;
  terraceOpen?: boolean;
  scoreDiff?: number;
}

export type Forecast = {
  updatedAt: number;
  days: Day[];
};

export type ReservationSnippet = {
  id: string;
  time: string;
  start: number;
  reservationLength: number;
  status: ReservationStatus;
  guests: number;
  attr: string[];
  comment: null | string;
  hostComment: null | string;
  name: string;
  occassion: string;
  source: string;
  tables: string[];
  date?: string;
};

export interface RichShift extends ReservationShift {
  reservations: ReservationSnippet[];
  automatic?: boolean;
}

export type Block = {
  block: number;
  time: string;
  reservations: ReservationSnippet[];
};

export type TranslationObject = {
  de?: string | object;
  en?: string | object;
  fr?: string | object;
  sp?: string | object;
  it?: string | object;
  jp?: string | object;
};

export type TakeAwayOption = {};

export type TakeAwayCartItem = {
  id: string;
  shortName: string;
  categoryName: string;
  options?: TakeAwayOption[];
  amount: number;
  price: number;
  canceled?: boolean;
};

export type TakeAwayOrder = {
  id: string;
  status:
    | 'failed'
    | 'pending'
    | 'seen'
    | 'done'
    | 'preperationDone'
    | 'inPreparation';
  date: string;
  billNumber: string;
  orderNumber: number;
  time: string;
  orderTime: string;
  paymentMethod: 'cash' | 'sofort' | 'sepa_debit' | 'card';
  sendAt: number;
  orderMethod: 'delivery' | 'pickup';
  customer: {
    name: string;
    email: string;
    phone: string;
    comment?: string;
    company?: string;
    persons?: string | number | null;
  };
  address?: {
    street: string;
    city: string;
    zipCode: string;
    adressZusatz: string;
    call?: boolean;
  };
  cart: TakeAwayCartItem[];
  paid: boolean;
  total: number;
  tip: number;
  deliveryFee: number;
  couponValue: number;
  couponId: null | string;
  createdAt: number;
  deliveryDuration?: number;
  emailStatus?: string;
  coordinates?: { lat: number; lng: number };
};

export type TakeAwayOrderSnippet = {
  createdAt: number;
  id: string;
  name: string;
  orderMethod: 'pickup' | 'delivery';
  paymentMethod: 'cash' | 'sofort' | 'sepa_debit' | 'card';
  status: string;
  time: string;
  tip: number;
  total: number;
  date: string;
};

export type Notification = {
  id: string;
  title: string;
  product?:
    | 'delivery'
    | 'feedback'
    | 'guestbook'
    | 'menu'
    | 'merchandise'
    | 'reservations'
    | 'vouchers';
  description: string;
  createdAt: number;
  seen?: boolean;
  hidden: boolean;
  action?: string;
};
