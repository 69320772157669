import { AlertState } from 'Components/Organisms/Alert';
import { Severity } from 'Contexts/RestaurantContext';
import {
  RequestV03Document,
  WaitingListEntryDocument,
} from 'gastronaut-shared/types/documents';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import React, { useEffect, useState } from 'react';
import {
  EditReservation,
  Reservation,
  ReservationStatus,
  ResSettings,
} from 'types/reservations';
import { db } from 'utils/firebase';
import { createRandomId, timeHelper, timeToBlock } from 'utils/helper';
import useToast from './useToast';
import useDateNow from './useDateNow';
import { useRouter } from './useRouter';
import useRestaurant from './useRestaurant';

export type ReservationSettings = {
  pendingTill?: number | undefined;
  standardFloorPlan?: string | undefined;
  maxGuestsOnWebsite?: number | undefined;
  hideResLengthInEmails?: boolean | undefined;
  phoneNumberMandatory?: boolean | undefined;
  addUserDetails?: boolean | undefined;
  signatureRequired?: boolean | undefined;
  customAttr?: string[] | undefined;
  minTimeInAdvance?: number | undefined;
  emailMandatory?: boolean;
  shuffleMode?: boolean;
};

const EMPTY_GUEST = {
  name: 'Wird gerade erstellt',
  email: '',
  phone: '',
  comment: '',
  preferredLanguage: 'de',
  sendEmail: false,
  attr: [],
};

const useEditReservation = (
  restaurant: string | null,
  updatedBy: string | null,
  alert: React.Dispatch<React.SetStateAction<AlertState | null>>,
  currentDate: string,
  setcurrentReservation: React.Dispatch<React.SetStateAction<string | null>>,
  settings?: ReservationSettings,
  isLightReservation?: boolean
) => {
  const { experimentalFlags } = useRestaurant();

  const [editReservation, seteditReservation] =
    useState<null | EditReservation>(null);

  const [originalUrl, setoriginalUrl] = useState<string | null>(null);

  const toast = useToast();

  const DateNow = useDateNow();

  const navigate = useRouter();

  const goBackToOriginal = () => {
    if (!originalUrl) return;
    if (!navigate.pathname.endsWith(originalUrl.split('?')[0])) {
      navigate.push(
        `/${restaurant}/reservations/${currentDate}/${originalUrl}`
      );
    }
    setoriginalUrl(null);
  };

  const handleRelocate = (
    reservation: Reservation | null,
    keepTables = reservation?.fixed ?? false
  ) => {
    if (reservation?.validTill) {
      toast('Diese Reservierung kann nicht bearbeitet werden.', Severity.ERROR);
      return;
    }

    if (reservation === null) {
      seteditReservation(null);
    } else {
      let {
        id,
        date,
        time,
        tables = [],
        reservationLength,
        occassion,
        guests,
        guest,
        walkIn,
        tableStr,
      } = reservation;

      let timeSlot = {
        time,
        block: timeToBlock(time),
        reservationLength,
        blockedSlots: 0,
        combinations: [],
        maxGuestsReached: false,
        totalSlots: 0,
      };

      console.log({
        keepTables,
      });

      seteditReservation({
        date,
        time,
        tables,
        tableStr,
        reservationLength,
        reservationId: id,
        occassion,
        guests,
        guest,
        walkin: walkIn,
        timeSlot,
        keepTables,
      });
    }
  };

  // const walkinResLength =

  const handleNewReservation = (
    date: string,
    walkin = false,
    tables: string[] | null = null,
    occassion?: string,
    space?: string
  ) => {
    let time,
      timeSlot = null;

    if (walkin) {
      time = timeHelper();

      timeSlot = {
        block: timeToBlock(time),
        blockedSlots: 0,
        combinations: [],
        maxGuestsReached: false,
        time,
        reservationLength: 4,
        totalSlots: 0,
      };

      if (!!experimentalFlags?.data?.walkinDefaultOccassion) {
        occassion = experimentalFlags.data.walkinDefaultOccassion;
      }
    }

    seteditReservation({
      date,
      tables,
      guest: null,
      space,
      time,
      occassion,
      walkin,
      timeSlot,
      reservationLength: undefined,
      page: 0,
      new: true,
      guests: walkin ? 2 : undefined,
      sendConfirmationEmail: true,
      keepTables: walkin,
    });
  };

  const submitReservation = async (sendConfirmationEmail = false) => {
    if (!editReservation?.timeSlot) return;

    let {
      time,
      block: startTimeInBlocks,
      // reservationLength,
    } = editReservation.timeSlot;

    let {
      guests = 0,
      date,
      occassion,
      tables = null,
      reservationId,
      space = null,
      waiterSignature,
      tableStr = null,
      sendNoShowSMS = false,
      reservationLength = editReservation?.timeSlot?.reservationLength ?? 4,
    } = editReservation;

    if (editReservation?.waitinglistEntry) {
      const {
        guest,
        customId,
        source,
        createdBy,
        createdAt,
        customData = {},
      } = editReservation.waitinglistEntry;

      const reservation: Omit<RequestV03Document, 'updateNote'> = {
        restaurant: restaurant || '',
        guest: {
          ...guest,
          sendEmail: sendConfirmationEmail,
        },
        guests: guests || editReservation.waitinglistEntry.guests,
        date,
        time,
        reservationLength,
        startTimeInBlocks,
        endTimeInBlocks: startTimeInBlocks + reservationLength,
        customId,
        source,
        customData,
        occassion:
          editReservation.occassion ||
          editReservation.waitinglistEntry.occassion,
        space,
        tables,
        createdAt,
        createdBy,
        validTill: 0,
        sendConfirmationEmail,
        status: ReservationStatus.SUCCESS,
        fromWaitinglist: true,
      };

      const ref = db
        .collection('requestsV03')
        .doc(editReservation.reservationId);

      try {
        const doc = await ref.get();

        if (doc.exists)
          return toast(
            'A Reservation allready exists for this Waitinglist entry.',
            Severity.ERROR
          );
      } catch (err) {
        console.error('No Permissions');
      }

      await ref.set({
        ...reservation,
        validTill: Date.now() + 15 * 60 * 1000,
        guest: EMPTY_GUEST,
      });

      const updatedAt = DateNow();

      seteditReservation(null);

      await ref.update({
        guest: reservation.guest,
        validTill: 0,
        updatedBy: waiterSignature ?? updatedBy ?? undefined,
        updatedAt,
        updateNote: {
          updatedAt,
          note: 'Reservierung wurde finalisiert',
        },
      });

      await db
        .collection('waitinglistEntries')
        .doc(editReservation.reservationId)
        .update({
          status: 'success',
          updatedAt,
          updatedBy: waiterSignature ?? updatedBy ?? undefined,
        });

      return;
    }

    if (editReservation?.new) {
      // New Reservation
      if (editReservation.walkin) {
        // @TODO: Load Settings Walkin Name Required

        reservationLength =
          editReservation.reservationLength ?? reservationLength;

        await db.collection('requestsV03').add({
          started: startTimeInBlocks,
          startTimeInBlocks,
          endTimeInBlocks: startTimeInBlocks + reservationLength,
          tables: tables || [],
          tableStr,
          time,
          reservationLength,
          date,
          guests,
          occassion,
          space,
          status: 'success',
          restaurant,
          guest: {
            name: `Walkin ${tables?.join(', ')}`,
            phone: '',
            email: '',
            hostComment: editReservation?.guest?.hostComment ?? '',
            comment: '',
            attr: [],
          },
          customId: createRandomId(),
          source: 'inHouse',
          validTill: 0,
          createdAt: DateNow(),
          createdBy: waiterSignature ?? updatedBy,
          walkIn: true,
        });

        seteditReservation(null);
      } else if (editReservation.page === 0) {
        let customId = createRandomId();

        if (editReservation.addToWaitinglist) {
          alert({
            title: 'Adding Guest to Waitinglist',
            description:
              'By Continueing you are placing the guest on the Waitinglist. No Reservation will be made. Make sure this has been communicated with the guest clearly.',
            descriptionTranslation: 'reservations',
            titleTranslation: 'reservations',
            actions: [
              { id: 'cancel', label: 'Cancel', onClick: () => {} },
              {
                id: 'submit',
                label: 'Continue',
                variant: 'primary',
                onClick: () => {
                  seteditReservation((eR) =>
                    eR === null ? null : { ...eR, page: 1, customId }
                  );
                },
              },
            ],
            onSubmit: () => {
              seteditReservation((eR) =>
                eR === null ? null : { ...eR, page: 1, customId }
              );
            },
          });

          return;
        }

        // @TODO: Pending Till

        let data = {
          startTimeInBlocks,
          endTimeInBlocks: startTimeInBlocks + reservationLength,
          tables,
          tableStr,
          time,
          reservationLength,
          date,
          guests,
          occassion,
          space,
          status: 'success',
          customId,
          restaurant,
          guest: EMPTY_GUEST,
          source: 'inHouse',
          validTill: DateNow() + 300000,
          createdAt: DateNow(),
          createdBy: waiterSignature ?? updatedBy,
        };

        if (!reservationId) {
          const { id } = await db.collection('requestsV03').add(data);

          reservationId = id;
        } else {
          await db.collection('requestsV03').doc(reservationId).set(data);
        }

        seteditReservation((eR) =>
          eR === null ? null : { ...eR, reservationId, page: 1, customId }
        );
      } else {
        let { guest, customData = {} } = editReservation;

        if (!guest || !guest.name) {
          // No TimeSlot Message
          alert({
            title: 'No Guest Information was entered!',
            titleTranslation: 'reservations',
            description: "You can't continue without the guest informations.",
            descriptionTranslation: 'reservations',
            onSubmit: () => {},
            actions: [
              {
                id: 'ok',
                label: 'Okay',
              },
            ],
          });

          return;
        }

        if (settings?.phoneNumberMandatory && guest.phone.length < 4) {
          alert({
            title: 'No Phone Number has been entered',
            titleTranslation: 'reservations',
            description: "You can't continue without the phone number.",
            descriptionTranslation: 'reservations',
            onSubmit: () => {},
            actions: [
              {
                id: 'ok',
                label: 'Okay',
              },
            ],
          });

          return;
        }

        if (settings?.emailMandatory && guest.email.length < 4) {
          alert({
            title: 'No Email entered',
            titleTranslation: 'reservations',
            description: 'You can not continue without an email.',
            descriptionTranslation: 'reservations',
            onSubmit: () => {},
            actions: [
              {
                id: 'ok',
                label: 'Okay',
              },
            ],
          });

          return;
        }

        if (settings?.signatureRequired && !waiterSignature) {
          alert({
            title: 'No Signature has been entered',
            titleTranslation: 'reservations',
            description: "You can't continue without the signature",
            descriptionTranslation: 'reservations',
            onSubmit: () => {},
            actions: [
              {
                id: 'ok',
                label: 'Okay',
              },
            ],
          });

          return;
        }

        console.log({ guest });

        if (guest?.id?.includes(`-${restaurant}`)) {
          guest.id = guest.id.replace(`-${restaurant}`, '');
        }

        if (editReservation.addToWaitinglist) {
          let {
            time,
            flexibleTime = null,
            preferredFormOfContact = 'call',
          } = editReservation.addToWaitinglist;

          let waitinglistEntry: Omit<WaitingListEntryDocument, 'id'> = {
            date,
            restaurant: restaurant || '',
            guests,
            time,
            flexibleTime,
            preferredFormOfContact,
            guest,
            occassion: occassion || '',
            source: 'inHouse',
            customId: editReservation.customId,
            type: 'waitinglist',
            status: RequestStatus.PENDING,
            createdAt: DateNow(),
            customData,
            createdBy: waiterSignature ?? updatedBy ?? '',
          };

          await db.collection('waitinglistEntries').add(waitinglistEntry);
        } else if (sendNoShowSMS) {
          await db
            .collection('requestsV03')
            .doc(reservationId)
            .update({
              guest,
              customData,
              updatedBy: waiterSignature ?? updatedBy,
              updatedAt: DateNow(),
              validTill: 0,
              updateNote: {
                updatedAt: DateNow(),
                note: 'No Show SMS wurde versendet',
              },
              noShowConfirmation: {
                validTill: DateNow() + 60 * 60000,
                sendVia: 'sms',
              },
            });
        } else {
          await db
            .collection('requestsV03')
            .doc(reservationId)
            .update({
              guest,
              customData,
              updatedBy: waiterSignature ?? updatedBy,
              updatedAt: DateNow(),
              updateNote: {
                updatedAt: DateNow(),
                note: 'Reservierung wurde finalisiert',
              },
              validTill: 0,
            });
        }

        seteditReservation(null);
      }
    } else {
      const ref = db.collection('requestsV03').doc(reservationId);

      let data: any = {
        time,
        startTimeInBlocks,
        endTimeInBlocks: startTimeInBlocks + reservationLength,
        guests,
        date,
        occassion,
        tables: tables || [],
        tableStr,
        updatedAt: DateNow(),
        updatedBy: waiterSignature ?? updatedBy,
        customData: editReservation?.customData ?? {},
        space,
        reservationLength,
        sendConfirmationEmail,
      };

      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          delete data[key];
        }
      });

      await ref.update(data);

      seteditReservation(null);

      if (currentDate === date) {
        setcurrentReservation(reservationId || null);
      }
    }
  };

  const handleSubmit = async () => {
    let sendConfirmationEmail = false;

    console.log({ editReservation, settings });

    if (
      (!!editReservation?.page || !editReservation?.new) &&
      settings?.signatureRequired &&
      !editReservation?.waiterSignature
    ) {
      alert({
        title: 'Signature is required',
        titleTranslation: 'reservations',
        description: 'You have to choose a signature to save your changes',
        descriptionTranslation: 'reservations',
        onSubmit: () => {},
        actions: [
          {
            id: 'ok',
            label: 'Okay',
          },
        ],
      });

      return;
    }

    if (
      (!editReservation?.new || !!editReservation?.waitinglistEntry) &&
      editReservation?.guest?.email &&
      editReservation.sendConfirmationEmail
    ) {
      sendConfirmationEmail = true;
    }

    if (!editReservation?.timeSlot || !editReservation.guests || !restaurant) {
      // No TimeSlot Message
      alert({
        title: 'No Time slot was selected!',
        titleTranslation: 'reservations',
        description:
          "You can't continue without a time for the reservation to start",
        descriptionTranslation: 'reservations',
        onSubmit: () => {},
        actions: [
          {
            id: 'ok',
            label: 'Okay',
          },
        ],
      });

      return;
    }

    if (
      !isLightReservation &&
      !editReservation.tables?.length &&
      !editReservation.addToWaitinglist
    ) {
      // No Tables Modal

      alert({
        title: 'No Tables where selected!',
        titleTranslation: 'reservations',
        description: 'Are you sure you want to continue?',
        descriptionTranslation: 'reservations',
        onSubmit: () => submitReservation(),
      });

      return;
    }

    await submitReservation(sendConfirmationEmail);
  };

  useEffect(() => {
    if (editReservation) {
      setoriginalUrl(editReservation.originalUrl ?? null);
    } else if (originalUrl) {
      goBackToOriginal();
    }
  }, [editReservation]);

  return {
    editReservation,
    seteditReservation,
    handleRelocate,
    handleSubmit,
    handleNewReservation,
  };
};

export default useEditReservation;
