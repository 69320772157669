import React, { useState } from 'react';
import {
  default as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from '@material-ui/core/TextField';
import { Size, Translations } from 'utils/types';
import { useTranslation } from 'react-i18next';
import { classHelper, sizeHelper } from 'utils/helper';
import './styles.scss';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { RemoveRedEye, RemoveRedEyeOutlined } from 'Components/Atoms/Icons';
import IconButton from '../IconButton';
import Box from '../Box';
import { TEST_TRANSLATIONS } from 'Contexts/LanguageContext';

// const MuiPhoneNumber = require("material-ui-phone-number");

type NewProps = {
  children?: React.ReactNode;
  label?: string;
  labelTranslation?: Translations;
  helperText?: string;
  helperTextTranslation?: Translations;
  errorMessage?: string;
  marginBottom?: Size;
  className?: string;
  paper?: boolean;
  rows?: number;
  hidePasswordIcon?: boolean;
  defaultCountry?: string;
};

export type TextFieldProps = MaterialTextFieldProps & NewProps;

const TextField = ({
  label = '',
  labelTranslation = 'common',
  helperText = '',
  helperTextTranslation = 'common',
  errorMessage = '',
  style = {},
  className,
  paper = false,
  placeholder,
  hidePasswordIcon = false,
  defaultCountry = 'de',
  ...props
}: TextFieldProps) => {
  const { t } = useTranslation(
    classHelper(['errors', labelTranslation, helperTextTranslation])
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const classNames = classHelper([
    'text-field',
    props.multiline ? 'multiline' : 'not-multiline',
    paper ? 'text-field-paper' : '',
    className,
    !label ? 'small' : '',
    props.type === 'phone' ? 'phone' : '',
    (!labelTranslation || labelTranslation === 'common') &&
      TEST_TRANSLATIONS &&
      'no-translation',
    // TEST_TRANSLATIONS && !!labelTranslation && "trans-" + labelTranslation,
  ]);

  if (props?.type === 'phone') {
    const handleChange = (value: string) => {
      console.log({ value, oldValue: props.value });

      if (value.startsWith('+0')) {
        if (defaultCountry === 'at') {
          value = value.replace('+0', '+43');
        } else {
          value = value.replace('+0', '+49');
        }
      }

      let res: any = { target: { value, name: props?.name || '' } };

      if (props?.onChange) props.onChange(res);
    };

    return (
      <MaterialUiPhoneNumber
        variant="filled"
        defaultCountry={defaultCountry}
        {...props}
        placeholder={
          placeholder ? t(labelTranslation + '_' + placeholder) : undefined
        }
        ref={null}
        size="small"
        className={classNames}
        color={props.color || 'primary'}
        label={t(labelTranslation + '_' + label)}
        helperText={
          errorMessage
            ? t(helperTextTranslation + '_' + errorMessage)
            : t(helperTextTranslation + '_' + helperText)
        }
        error={!!errorMessage}
        style={{
          ...style,
          marginBottom: sizeHelper(props?.marginBottom ?? 'sm'),
        }}
        onChange={handleChange}
      />
    );
  }

  if (props.multiline) {
    return (
      <MaterialTextField
        variant="filled"
        {...props}
        size="small"
        placeholder={
          placeholder ? t(labelTranslation + '_' + placeholder) : undefined
        }
        multiline
        className={classNames}
        color={props.color || 'primary'}
        label={t(labelTranslation + '_' + label)}
        helperText={
          errorMessage
            ? t(helperTextTranslation + '_' + errorMessage)
            : t(helperTextTranslation + '_' + helperText)
        }
        error={!!errorMessage}
        style={{
          ...style,
        }}
        disabled={props.disabled}
      />
    );
  }

  return props.type === 'password' && !hidePasswordIcon ? (
    <Box className="text-field-container">
      <MaterialTextField
        variant="filled"
        {...props}
        placeholder={
          placeholder ? t(labelTranslation + '_' + placeholder) : undefined
        }
        size="small"
        className={classNames}
        color={props.color || 'primary'}
        label={t(labelTranslation + '_' + label)}
        helperText={
          errorMessage
            ? t(helperTextTranslation + '_' + errorMessage)
            : t(helperTextTranslation + '_' + helperText)
        }
        error={!!errorMessage}
        style={{
          ...style,
          marginBottom: sizeHelper(props?.marginBottom ?? 'sm'),
        }}
        type={!showPassword ? 'password' : 'text'}
        disabled={props.disabled}
      />
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        size="x-small"
        className="toggle-password-icon"
      >
        {showPassword ? <RemoveRedEye /> : <RemoveRedEyeOutlined />}
      </IconButton>
    </Box>
  ) : (
    <MaterialTextField
      variant="filled"
      {...props}
      InputLabelProps={{
        shrink: props.type === 'date' || !!props.value,
        ...props.InputLabelProps,
      }}
      size="small"
      placeholder={
        placeholder ? t(labelTranslation + '_' + placeholder) : undefined
      }
      className={classNames}
      color={props.color || 'primary'}
      label={t(labelTranslation + '_' + label)}
      helperText={
        errorMessage
          ? t(helperTextTranslation + '_' + errorMessage)
          : t(helperTextTranslation + '_' + helperText)
      }
      error={!!errorMessage}
      style={{
        ...style,
        marginBottom: sizeHelper(props?.marginBottom ?? 'sm'),
      }}
      disabled={props.disabled}
    />
  );
};

export default TextField;
