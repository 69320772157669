import React, { useContext, useEffect } from 'react';
import { AuthContext, AuthContextType } from 'Contexts/AuthContext';
import { useIntercom } from 'react-use-intercom';
import Login from './Login';

const LoginWithGastronautContainer = () => {
  const { signInUser, user } = useContext<AuthContextType>(AuthContext);
  const { shutdown, hide } = useIntercom();

  useEffect(() => {
    shutdown();
    hide();
  }, []);

  return <Login onSignIn={signInUser} signInError={user.signInError} />;
};

export default LoginWithGastronautContainer;
