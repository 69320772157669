import React, { useMemo } from 'react';
import useRestaurant from './useRestaurant';
import useDocument from './useDocument';
import { encodeJWT } from 'utils/webtoken';

const useGuestbookVersion = () => {
  const { apps, restaurantId } = useRestaurant();

  const [state] = useDocument<{
    active: boolean;
    settings: { tableName: string; url: string; token: string };
  }>(`/restaurants/${restaurantId || ''}/apps`, 'guestbook');

  const token = useMemo(() => {
    return (
      encodeJWT({
        restaurantId,
        tableName: state.data?.settings.tableName,
      }) ?? state.data?.settings.token
    );
  }, [state.data?.settings]);

  return {
    version: apps.includes('guestbook') ? 'v02' : 'v01',
    settings: state.data?.settings,
    token,
  };
};

export default useGuestbookVersion;
