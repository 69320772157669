import React, { useContext, useEffect, useMemo, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useSiaContext from 'CustomHooks/useSiaContext';
import { classHelper } from 'utils/helper';
import { IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import SiaModalCard from './Components/SiaModalCard';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import CallListItem, { NextPage } from './Components/CallListItem';
import CurrentCall from './Components/CurrentCall';
import CallSettings from './Components/CallSettings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import firebase from 'utils/firebase';
import useAuth from 'CustomHooks/useAuth';
import { ThemeContext } from 'Contexts/ThemeContext';

export type SiaModalProps = {
  onClose?: () => void;
  className?: string;
  mobile?: boolean;
};

const SiaModal: React.FC<SiaModalProps> = ({
  onClose = () => {},
  className = '',
  mobile = false,
}) => {
  const {
    calls,
    currentCall,
    onChangeCurrentCall,
    callStatistics,
    v02,
    restaurantId,
  } = useSiaContext();

  const { user } = useAuth();

  const [tab, settab] = useState(0);
  const [visible, setVisible] = useState(true);

  const filteredCalls = useMemo(() => {
    return calls.filter((c) => {
      if (tab === 0) {
        return c.first;
      } else {
        return (
          c.first &&
          !!c.endedAt &&
          !c.events?.[(c?.events?.length ?? 1) - 1]?.success
        );
      }
    });
  }, [calls, tab]);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 450px)');

  const [idToken, setIdToken] = useState<string | null>(null);

  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    const user = firebase.auth().currentUser;

    if (!!user) {
      user
        .getIdToken()
        .then((idToken) => {
          setIdToken(idToken);
        })
        .catch((error) => {
          setIdToken(null);
        });
    }
  }, [user]);

  if (v02) {
    const SIA_PHONE_ASSISTANT_URL = 'https://sia-app.gastronaut.ai';

    const url = `${SIA_PHONE_ASSISTANT_URL}/${restaurantId}?token=${idToken}&restaurantId=${restaurantId}&dark=${
      darkMode ? 'true' : 'false'
    }`;

    return (
      <Box
        className={classHelper([
          'siaModal v02',
          className,
          !visible && 'invisible',
        ])}
      >
        <Box className="mainWindowV02" elevation={4}>
          <iframe
            src={url}
            allow="microphone"
            title="SIA Phone Assistant"
            style={{ width: '100%', height: '100%', border: 0 }}
          />
        </Box>
        {!isMobile && (
          <>
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                top: 20,
                left: -36,
                padding: 6,
                borderRadius: '16px 0px 0px 16px',
              }}
              className="sia__v02__btn"
              onClick={(e) => {
                e.stopPropagation();
                if (isMobile) document.body.style.overflow = 'auto';
                onClose();
                setVisible(true);
              }}
            >
              <Close />
            </IconButton>
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                top: 70,
                left: -36,
                padding: 6,
                borderRadius: '16px 0px 0px 16px',
              }}
              className="sia__v02__btn"
              onClick={(e) => {
                e.stopPropagation();
                setVisible(!visible);
              }}
            >
              {visible ? <ArrowForward /> : <ArrowBack />}
            </IconButton>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box className={classHelper(['siaModal', className])}>
      <Box className="mainWindow paper" outline elevation={4}>
        {!mobile && (
          <Box className="flex space-between mg-bt-sm">
            <Typography block variant="h5">
              Call Manager
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (isMobile) document.body.style.overflow = 'auto';
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
        )}

        <SiaModalCard
          onOpenSettings={() => {
            setSettingsOpen(true);
            onChangeCurrentCall(null);
            if (isMobile) document.body.style.overflow = 'hidden';
          }}
        />
        <Box className="tabs">
          <Button
            className={tab === 0 ? 'active' : ''}
            onClick={() => settab(0)}
            variant="transparent"
          >
            All Calls
          </Button>
          <Button
            className={tab === 1 ? 'active' : ''}
            onClick={() => settab(1)}
            variant="transparent"
          >
            Re-Check
          </Button>
        </Box>
        <div className="list" id="callList">
          {filteredCalls.map((call) => (
            <CallListItem
              key={call.id}
              call={call}
              active={currentCall?.id === call.id}
              onClick={() => setSettingsOpen(false)}
            />
          ))}
          {filteredCalls.length >= 1 && <NextPage />}
        </div>
        <div style={{ paddingTop: 8, textAlign: 'right' }}>
          <Typography variant="text-3" style={{ marginRight: 8 }}>
            Heute:
          </Typography>
          <Typography variant="text-3" style={{ marginRight: 8 }}>
            {callStatistics?.calls ?? 0} Anrufe
          </Typography>
          <Typography variant="text-3">
            {callStatistics?.reservations ?? 0} Reservierungen
          </Typography>
        </div>
      </Box>
      {!!currentCall && !settingsOpen && <CurrentCall />}
      {settingsOpen && <CallSettings onClose={() => setSettingsOpen(false)} />}
    </Box>
  );
};

export default SiaModal;
