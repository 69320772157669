import React, { useContext, useMemo } from 'react';
import './styles.scss';
import Box from 'Components/Atoms/Box';
import Typography from 'Components/Atoms/Typography';

import { Forecast } from 'utils/types';

import MobileReservationCard from 'App/Dashboard/Components/MobileReservationCard';
import MobileDashboardCard from 'App/Dashboard/Components/MobileDashboardCard';
import { ReservationContext } from 'Contexts/ReservationContext';
import { reservationToSnippet } from 'utils/helper';
import { ReservationStatus } from 'types/reservations';
import MobileStaticCard from 'App/Dashboard/Components/MobileStaticCard';
import { RestaurantContext } from 'Contexts/RestaurantContext';
import MobileProductIcons from 'App/Dashboard/Components/MobileProductIcons';
import SnappyScroll from 'Components/Organisms/SnappyScroll';
import ScrollToTopWrapper from 'App/Mobile/Components/ScrollToTopWrapper/ScrollToTopWrapper';
import MobileQuicklook from '../QuicklookPage/MobileQuicklook';

type MobileHomepageProps = {
  forecast: null | Forecast;
  update?: () => void;
};

const MobileHomepage = ({ forecast = null, update }: MobileHomepageProps) => {
  const { reservations, occassions = [] } = useContext(ReservationContext);
  const { restaurantId, products, apps } = useContext(RestaurantContext);

  // const reservationSnippets = useMemo(
  //   () =>
  //     reservations
  //       .filter((r) => !r.blockedFullShift && r.guests)
  //       .map(reservationToSnippet),
  //   [reservations]
  // );

  // const reservationsByOccassions = useMemo(() => {
  //   return occassions.map((occ) => ({
  //     value: reservationSnippets
  //       .filter(
  //         (res) =>
  //           res.occassion === occ.id && res.status !== ReservationStatus.FAILED
  //       )
  //       .reduce((acc: number, cV) => acc + cV.guests, 0),
  //     label: occ.title,
  //     id: occ.id,
  //   }));
  // }, [reservationSnippets, occassions]);

  // const reservationBySource = useMemo(() => {
  //   let sourceTable = reservationSnippets
  //     .filter((res) => res.status !== ReservationStatus.FAILED)
  //     .reduce((acc: any, cV) => {
  //       if (!acc[cV.source]) {
  //         acc[cV.source] = cV.guests;
  //       } else {
  //         acc[cV.source] += cV.guests;
  //       }

  //       return acc;
  //     }, {});

  //   return Object.keys(sourceTable).map((id) => ({
  //     id,
  //     label: id,
  //     value: sourceTable[id],
  //   }));
  // }, [reservationSnippets]);

  return (
    <ScrollToTopWrapper>
      <Box className="mobile-homepage-body">
        {/* <SnappyScroll className="cards">
          <MobileReservationCard forecast={forecast} update={update} />
          <MobileDashboardCard
            title="Occassion"
            titleTranslation="settings"
            data={reservationsByOccassions}
          />
          <MobileDashboardCard
            title="Source"
            titleTranslation="settings"
            data={reservationBySource}
          />
          <MobileStaticCard
            title="Group by time"
            subtitle="(15 minutes)"
            titleTranslation="dashboard"
            subtitleTranslation="dashboard"
            image="time"
          />
          <MobileStaticCard
            title="Group by space"
            subtitle="(15 minutes)"
            titleTranslation="dashboard"
            subtitleTranslation="dashboard"
            image="space"
          />
        </SnappyScroll> */}
        <Box className="products" padding="md">
          <Typography
            variant="h3"
            style={{ marginBottom: 16 }}
            translation="dashboard"
          >
            Current Components
          </Typography>
          <MobileProductIcons
            products={products}
            restaurantId={restaurantId}
            apps={apps}
          />
        </Box>
      </Box>
      <MobileQuicklook
        update={() => {
          update?.();
        }}
        forecast={forecast}
      />
    </ScrollToTopWrapper>
  );
};

export default MobileHomepage;
