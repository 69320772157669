import { useState, useEffect, useCallback, useRef } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { Guest as Relationship } from 'types/reservations';
import TextField from 'Components/Atoms/TextField';
import Popover from 'Components/Atoms/Popover';
import Button from 'Components/Atoms/Button';
import { MergeType, PersonAdd } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { noop } from 'utils/helper';
import useGuestAutoComplete from 'CustomHooks/useGuestAutoComplete';
import useRestaurant from 'CustomHooks/useRestaurant';
import IconButton from 'Components/Atoms/IconButton';
import { CircularProgress } from '@material-ui/core';
import useGuestbookVersion from 'CustomHooks/useGuestbookVersion';
import { encodeJWT } from 'utils/webtoken';

export type GuestDropdownProps = {
  value: null | Relationship;
  onChange: (guest: Relationship) => void;
};

const EMPTY_GUEST: Relationship = {
  name: '',
  id: '',
  phone: '',
  email: '',
  hostComment: '',
  guestComment: '',
  comment: '',
  attr: [],
};

const GuestDropdownV02 = ({ value, onChange = noop }: GuestDropdownProps) => {
  const { restaurantId } = useRestaurant();

  const [limit, setlimit] = useState(25);

  const {
    results: searchResults,
    search,
    isConnected,
    sendMessage,
    duplicates,
    handleSelectGuest,
    duplicateLoading,
  } = useGuestSearch(restaurantId || '', (g) => onChange(g(value)));

  console.log(limit);

  useEffect(() => {
    if (isConnected) {
      search(value?.name || '', limit);
    }
  }, [limit, isConnected]);

  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const timeout = useRef<null | NodeJS.Timeout>(null);

  const handleFocus = (e: any) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setTimeout(() => {
      setanchorEl(e.target);
    }, 200);
  };

  const handleBlur = () => {
    if (!anchorEl) return;

    timeout.current = setTimeout(() => {
      setanchorEl(null);
    }, 400);
  };

  const handleNewGuest = () => {
    onChange({
      ...EMPTY_GUEST,
      phone: value?.phone ?? '',
      email: value?.email ?? '',
      company: value?.company ?? '',
      comment: value?.comment ?? '',
      name: value?.name ?? '',
      createNew: true,
    });
    handleBlur();
  };

  const handleChange = (e: any) => {
    if (!anchorEl) {
      setTimeout(() => {
        setanchorEl(e.target);
      }, 200);
    }
    const name = e.target.value;
    onChange(
      value ? { ...value, name, createNew: false } : { ...EMPTY_GUEST, name }
    );
    search(name);
    setlimit(25);
  };

  const handleGuestClick = (id: string) => {
    let guest = searchResults.find((g) => id === g.id);
    onChange({
      ...EMPTY_GUEST,
      name: guest?.name || '',
      id: guest?.id || '',
      phone: guest?.phone || value?.phone || '',
      email: guest?.email || value?.email || guest?.alternative_emails[0] || '',
      guestComment: guest?.guest_comment || '',
      comment: value?.comment || '',
      attr: Array.from(
        new Set([...(guest?.attr || []), ...(value?.attr || [])])
      ),
      company: guest?.company || '',
      alternativePhoneNumbers: (guest?.alternative_phones || []).filter(
        (p) => p !== guest?.phone
      ),
      preferredLanguage: guest?.language || value?.preferredLanguage || '',
      anniversary: guest?.anniversary || '',
      birthday: guest?.birthday || '',
    });
    if (guest) handleSelectGuest(id);
    handleBlur();
  };

  return (
    <Box className="GuestDropdown">
      <TextField
        value={value?.name || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fullWidth
        required
        label="Name of Guest"
        labelTranslation="reservations"
      />
      {!!(duplicates.length || duplicateLoading) && (
        <Box>
          <Typography block variant="text-3" weight="bold">
            Mögliche Duplikate
          </Typography>
          {!!duplicates.length && (
            <Box style={{ overflowY: 'scroll', maxHeight: 145 }}>
              {duplicates.map((d) => {
                return (
                  <Box
                    padding="sm"
                    key={d.duplicate.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box>
                      <Typography block variant="text-3" weight="bold">
                        {d.duplicate.name}
                      </Typography>
                      {!!d.duplicate.phone && (
                        <Typography block variant="text-4" color="subdued">
                          {d.duplicate.phone}
                        </Typography>
                      )}
                      {!!d.duplicate.email && (
                        <Typography block variant="text-4" color="subdued">
                          {d.duplicate.email}
                        </Typography>
                      )}
                    </Box>
                    <IconButton
                      onClick={() => {
                        if (value?.id) {
                          sendMessage({
                            type: 'merge',
                            id: value?.id,
                            duplicates: [d.duplicate.id],
                          });
                        }
                      }}
                    >
                      <MergeType style={{ transform: 'rotate(90deg)' }} />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          )}
          {duplicateLoading && (
            <Box
              style={{
                height: 145,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
      <Popover
        anchorEl={anchorEl}
        onClose={handleBlur}
        open={!!anchorEl}
        placement="left-start"
        padding={0}
      >
        <Box
          component="div"
          style={{ maxHeight: 300, overflow: 'scroll' }}
          onScroll={(e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.target as any;

            if ((scrollTop + clientHeight) / scrollHeight > 0.97) {
              setlimit(
                Math.min(Math.max(Math.round(scrollHeight / 50), 25) * 2, 100)
              );
            }
          }}
        >
          {searchResults.map((res) => (
            <Box
              key={res.id}
              padding="sm"
              underline
              onClick={() => handleGuestClick(res?.id || '')}
              className="guest"
            >
              <Typography block variant="text-3">
                {res.name}
              </Typography>
              <Typography block variant="text-4" color="subdued">
                {res.phone}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box padding="sm">
          <Button
            fullWidth
            endIcon={(p) => <PersonAdd {...p} />}
            onClick={handleNewGuest}
          >
            New Guest
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default GuestDropdownV02;

type SearchResult = Guest;

type UseWebSocketSearch = {
  results: SearchResult[];
  error: string | null;
  isConnected: boolean;
  search: (query: string, l?: number) => void;
  duplicates: Duplicate[];
  handleSelectGuest: (id: string) => void;
  duplicateLoading: boolean;
  mergeGuests: (mergeInto: string, duplicate: string) => void;
  sendMessage: (message: WebsocketRequest) => void;
};

export const useGuestSearch = (
  restaurantId: string,
  onChange: (fn: (guest: Relationship | null) => Relationship) => void,
  limit = 25,
  offset = 0
): UseWebSocketSearch => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [duplicates, setDuplicates] = useState<Duplicate[]>([]);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [reconnect, setReconnect] = useState(0);
  const [pendingSearch, setPendingSearch] = useState<WebsocketRequest | null>(
    null
  );

  const sendMessage = useCallback(
    (message: WebsocketRequest) => {
      if (ws?.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(message));
      }
    },
    [ws]
  );

  const { settings } = useGuestbookVersion();

  useEffect(() => {
    if (ws) {
      ws.close();
      setWs(null);
      setIsConnected(false);
    }

    if (settings) {
      const socket = new WebSocket(
        `wss://${settings.url}/ws/${settings.tableName}/${restaurantId}`
      );

      console.log('Connecting to ws', ws);

      socket.onopen = () => setIsConnected(true);
      socket.onclose = () => setIsConnected(false);
      socket.onerror = () => setError('Connection failed');

      socket.onmessage = (event) => {
        const props = JSON.parse(event.data) as WebsocketResponse;

        switch (props.type) {
          case 'results': {
            setResults(props.data);
            break;
          }
          case 'selected': {
            const guest = props.data;
            onChange((g) => ({
              id: guest.id,
              name: guest.name,
              phone: guest.phone,
              email: guest.email,
              company: guest.company,
              attr: guest.attr,
              guestComment: guest.guest_comment,
              comment: g?.comment ?? '',
              alternativePhoneNumbers: guest.alternative_phones.filter(
                (p) => p !== guest.phone
              ),
              preferredLanguage: guest.language,
              anniversary: guest.anniversary || g?.anniversary || '',
              birthday: guest.birthday || g?.birthday || '',
              alternativeEmails: guest.alternative_emails,
              marketing: guest.marketing_consent || g?.marketing || false,
              sendEmail: guest.email_consent || g?.sendEmail || false,
            }));
            setDuplicateLoading(props.duplicatesLoading || false);
            setDuplicates(guest?.duplicates || []);
            break;
          }
          case 'merged': {
            const guest = props.data;
            onChange((g) => ({
              id: guest.id,
              name: guest.name,
              phone: guest.phone,
              email: guest.email,
              company: guest.company,
              attr: guest.attr,
              guestComment: guest.guest_comment,
              comment: g?.comment ?? '',
              alternativePhoneNumbers: guest.alternative_phones.filter(
                (p) => p !== guest.phone
              ),
              preferredLanguage: guest.language,
              anniversary: guest.anniversary || g?.anniversary || '',
              birthday: guest.birthday || g?.birthday || '',
              alternativeEmails: guest.alternative_emails,
              marketing: guest.marketing_consent || g?.marketing || false,
              sendEmail: guest.email_consent || g?.sendEmail || false,
            }));
            setDuplicates((d) =>
              d.filter((x) => guest.old_ids.includes(x.duplicate.id))
            );
            break;
          }
          case 'error': {
            setError(props.message);
            break;
          }
          default:
            console.warn('Unknown message', props);
            break;
        }
      };

      setWs(socket);

      return () => socket.close();
    }
  }, [restaurantId, reconnect, settings]);

  const search = useCallback(
    (query: string, l = limit) => {
      if (ws?.readyState === WebSocket.OPEN) {
        sendMessage({
          type: 'search',
          q: query,
          perPage: l,
          page: Math.floor(offset / l),
        });
      } else {
        setReconnect((r) => r + 1);
        setPendingSearch({
          type: 'search',
          q: query,
          perPage: l,
          page: Math.floor(offset / l),
        });
      }
    },
    [ws, limit, offset]
  );

  const handleSelectGuest = useCallback(
    (id: string) => {
      if (ws?.readyState === WebSocket.OPEN) {
        sendMessage({ type: 'select', id });
      } else {
        const guest = results.find((g) => g.id === id);

        if (!!guest) {
          onChange((g) => ({
            id: guest.id,
            name: guest.name,
            phone: guest.phone,
            email: guest.email,
            company: guest.company,
            attr: guest.attr,
            guestComment: guest.guest_comment,
            comment: g?.comment ?? '',
            alternativePhoneNumbers: guest.alternative_phones.filter(
              (p) => p !== guest.phone
            ),
            preferredLanguage: guest.language,
            anniversary: guest.anniversary || g?.anniversary || '',
            birthday: guest.birthday || g?.birthday || '',
            alternativeEmails: guest.alternative_emails,
            marketing: guest.marketing_consent || g?.marketing || false,
            sendEmail: guest.email_consent || g?.sendEmail || false,
          }));
        }

        setReconnect((r) => r + 1);
        setPendingSearch({ type: 'select', id });
      }
    },
    [ws, limit, offset]
  );

  const mergeGuests = useCallback(
    (mergeInto: string, duplicate: string) => {
      if (ws?.readyState === WebSocket.OPEN) {
        sendMessage({
          type: 'merge',
          id: mergeInto,
          duplicates: [duplicate],
        });
      } else {
        setReconnect((r) => r + 1);
        setPendingSearch({
          type: 'merge',
          id: mergeInto,
          duplicates: [duplicate],
        });
      }
    },
    [ws, limit, offset]
  );

  useEffect(() => {
    if (pendingSearch && isConnected && ws?.readyState === WebSocket.OPEN) {
      sendMessage(pendingSearch);
      setPendingSearch(null);
    }
  }, [ws, isConnected]);

  return {
    results,
    error,
    isConnected,
    search,
    duplicates,
    handleSelectGuest,
    duplicateLoading,
    mergeGuests,
    sendMessage,
  };
};

type Guest = {
  id: string; // UUID of the guest
  name: string; // Name of the customer
  email: string; // Email address
  phone: string; // Phone number
  company: string; // Company name
  language: string; // Language code
  anniversary: string | null;
  birthday: string | null;
  old_ids: string[]; // List of old IDs
  alternative_phones: string[]; // List of alternative phone numbers
  alternative_emails: string[]; // List of alternative email addresses
  attr: string[]; // Attributes, such as 'vip' or 'regular'
  guest_comment: string; // Guest comments
  last_visit: string | null; // Last visit date in 'yyyy-mm-dd' format or null if not available
  number_of_visits: number; // Number of visits
  number_of_no_shows: number; // Number of no-shows
  marketing_consent: boolean; // Marketing consent
  mollie_customer_id: string | null; // Mollie customer ID
  email_consent: boolean; // Email consent
  relevance: number; // Calculated relevance score
  created_at: Date; // When the guest was created
  updated_at: Date; // When the guest was last updated
};

type CreateGuest = Omit<
  Guest,
  | 'id'
  | 'relevance'
  | 'number_of_visits'
  | 'number_of_no_shows'
  | 'last_visit'
  | 'marketing_consent'
  | 'email_consent'
  | 'mollie_customer_id'
  | 'created_at'
  | 'updated_at'
  | 'company'
  | 'language'
  | 'anniversary'
  | 'birthday'
  | 'old_ids'
> &
  Partial<
    Pick<
      Guest,
      | 'last_visit'
      | 'number_of_no_shows'
      | 'number_of_visits'
      | 'id'
      | 'marketing_consent'
      | 'email_consent'
      | 'mollie_customer_id'
      | 'anniversary'
      | 'birthday'
      | 'company'
      | 'language'
      | 'old_ids'
    >
  >;

type Duplicate = {
  duplicate: Guest;
  matchingField: {
    name: boolean;
    email: boolean;
    phone: boolean;
  };
  probability_score: number;
};

type WebsocketResponse =
  | {
      type: 'error';
      message: string;
    }
  | {
      type: 'results';
      data: Guest[];
      count?: number;
    }
  | {
      type: 'selected';
      data: Guest & {
        duplicates?: Duplicate[];
      };
      duplicatesLoading?: boolean;
    }
  | {
      type: 'merged' | 'updated';
      data: Guest;
    }
  | {
      type: 'deleted';
      id: string;
    }
  | {
      type: 'download';
      data: string;
    }
  | {
      type: 'added';
      data: Guest;
    };

type WebsocketRequest =
  | {
      type: 'search' | 'downloadAsCSV';
      q?: string;
      perPage?: number;
      page?: number;
      getCount?: boolean;
      filters?: `${keyof Guest} ${'=' | '<=' | '<' | '>' | '!=' | 'CONTAINS'} ${
        | string
        | number
        | `ANY(${string})`}`[];
      sort?: `${keyof Guest} ${'ASC' | 'DESC'}`;
    }
  | {
      type: 'select';
      id: string;
    }
  | {
      type: 'merge';
      id: string;
      duplicates: string[];
    }
  | {
      type: 'update';
      data: Partial<Guest> & { id: string };
    }
  | {
      type: 'delete';
      id: string;
    }
  | {
      type: 'add';
      data: CreateGuest;
    };
