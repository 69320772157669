import React from 'react';
import Box from 'Components/Atoms/Box';
import ProductIcon from '../ProductIcon';
import './styles.scss';
import { Products } from 'utils/types';
import { dateHelper, noop } from 'utils/helper';
import useRestaurant from 'CustomHooks/useRestaurant';
import { AppId } from 'gastronaut-shared/types/documents/restaurants';
import usePinAuth, { SecurityPlusKeys } from 'CustomHooks/usePinAuth';

import firebase from 'utils/firebase';

export type ProductIconsProps = {
  products?: Products[];
  takeAwayNotifications?: number;
  restaurantId?: null | string;
};

const PRODUCTS: {
  id: string;
  product: string;
  label: string;
  linkTo?: (rId: string, apps: AppId[]) => string;
  authorize?: SecurityPlusKeys;
  app?: AppId;
  isNew?: boolean;
  hideLabel?: string;
  dontShowOnHide?: boolean;
  promotionalLink?: (rId: string) => string;
  onClick?: (rId: string | null) => void | Promise<void>;
}[] = [
  {
    id: 'reservations',
    product: 'reservation',
    label: 'Reservations',
    linkTo: (rId: string) => `${rId}/reservations/${dateHelper()}/tablePlan`,
  },
  {
    id: 'light-reservations',
    product: 'light-reservation',
    label: 'Reservations',
    linkTo: (rId: string) =>
      `/${rId}/reservations/${dateHelper()}/reservationList`,
  },

  {
    id: 'calendar',
    product: 'light-reservation, reservation',
    label: 'Calendar',
    linkTo: (rId: string) => `/${rId}/calendar/${dateHelper()}`,
    authorize: 'dashboard.calendar.read',
  },
  {
    id: 'guestbook',
    product: 'light-reservation, reservation',
    label: 'Guest Book',
    linkTo: (rId: string, apps) =>
      apps.includes('guestbook') ? `/${rId}/guestbookV02` : `/${rId}/guestbook`,
    authorize: 'dashboard.guestBook.read',
  },
  {
    id: 'guest-statistics',
    product: 'general',
    label: 'Guest Statistics',
    linkTo: (rId: string) => `/${rId}/statistics`,
    authorize: 'dashboard.statistics.read',
  },
  {
    id: 'delivery',
    product: 'delivery',
    label: 'Take Away',
    linkTo: (rId: string) => `/${rId}/takeAway/orders/${dateHelper()}`,
    authorize: 'dashboard.delivery.read',
    dontShowOnHide: true,
  },
  {
    id: 'menu',
    product: 'menu',
    label: 'Digital Menu',
    app: 'digital_menu',
    linkTo: (rId: string) => `/${rId}/menu`,
    authorize: 'dashboard.menu.read',
  },
  {
    id: 'feedback',
    product: 'feedback, followUp',
    label: 'Review Rocket',
    linkTo: (rId: string) => `${rId}/feedback`,
    authorize: 'dashboard.feedback.read',
  },
  {
    id: 'phone',
    product: 'phone',
    label: 'SIA',
    app: 'sia',
    linkTo: (rId: string) => `/${rId}/sia`,
    authorize: 'dashboard.sia.read',
  },
  {
    id: 'addOns',
    product: 'reservation, addOns, addons',
    label: 'Add-Ons',
    linkTo: (rId: string) => `/${rId}/experiences`,
    hideLabel: 'COMING SOON',
    // isNew: true,
    dontShowOnHide: true,
  },
  {
    id: 'vouchers',
    product: 'vouchers',
    label: 'Vouchers',
    // hideLabel: "COMING SOON",
    linkTo: (rId: string) => `/${rId}/vouchers`,
    authorize: 'dashboard.voucher.read',
    isNew: true,
    promotionalLink: (rId: string) =>
      `/${rId}/settings/general/apps?appId=voucher`,
    // dontShowOnHide: true,
  },
  {
    id: 'newsletter',
    product: 'newsletters',
    label: 'Newsletter',
    // hideLabel: "COMING SOON",
    linkTo: (rId: string) => `/${rId}/newsletters`,
    dontShowOnHide: true,
  },
  {
    id: 'tickets',
    product: 'reservation',
    app: 'gastronaut-events',
    label: 'Events',
    onClick: async (rId: string | null) => {
      try {
        const user = firebase.auth().currentUser;

        const GASTRONAUT_EVENTS_URL = 'http://localhost:5173';

        if (user) {
          const idToken = await user.getIdToken(/* forceRefresh */ true);
          // Redirect to App B with the token
          window.location.href = `${GASTRONAUT_EVENTS_URL}/${
            rId || ''
          }?token=${idToken}`;
        } else {
          // Handle the case where the user is not logged in
        }
      } catch (error) {
        window.alert(error);
      }
    },
    dontShowOnHide: true,
  },
  {
    id: 'giftcards',
    product: 'vouchers',
    app: 'gastronaut-giftcards',
    label: 'Gift Cards',
    onClick: async (rId: string | null) => {
      try {
        const user = firebase.auth().currentUser;

        const GASTRONAUT_GIFTCARD_URL = 'http://localhost:5173';

        if (user) {
          const idToken = await user.getIdToken(/* forceRefresh */ true);
          // Redirect to App B with the token
          window.location.href = `${GASTRONAUT_GIFTCARD_URL}/${
            rId || ''
          }?token=${idToken}`;
        } else {
          // Handle the case where the user is not logged in
        }
      } catch (error) {
        window.alert(error);
      }
    },
    dontShowOnHide: true,
  },
  // {
  //   id: 'phone',
  //   product: 'general',
  //   app: 'sia-phone-assistant',
  //   label: 'Sia 2.0',
  //   onClick: async (rId: string | null) => {
  //     try {
  //       const user = firebase.auth().currentUser;

  //       const SIA_PHONE_ASSISTANT_URL = 'https://sia-app.gastronaut.ai';

  //       if (user) {
  //         const idToken = await user.getIdToken(/* forceRefresh */ true);
  //         // Redirect to App B with the token
  //         window.location.href = `${SIA_PHONE_ASSISTANT_URL}/login?token=${idToken}&restaurantId=${rId}`;
  //       } else {
  //         // Handle the case where the user is not logged in
  //       }
  //     } catch (error) {
  //       window.alert(error);
  //     }
  //   },
  //   isNew: true,
  //   dontShowOnHide: true,
  // },
  // {
  //   id: "order",
  //   product: "order",
  //   label: "Guest Order",
  //   linkTo: (rId: string) => `${rId}/order`,
  //   hideLabel: "COMING SOON",
  // },
];

const ProductIcons = ({
  products = [],
  takeAwayNotifications = 0,
  restaurantId = null,
}: ProductIconsProps) => {
  const { apps } = useRestaurant();

  const { checkAuthentication } = usePinAuth();

  const filteredProducts = () => {
    return PRODUCTS.filter(
      (p) =>
        !(
          (products.includes('light-reservation') && p.id === 'reservations') ||
          (products.includes('reservation') && p.id === 'light-reservations')
        )
    )
      .map((p) => ({
        ...p,
        locked: !!p.authorize && checkAuthentication(p.authorize),
        hide:
          !products.some((x) => p.product.includes(x)) ||
          !(!p.app || apps.includes(p.app as AppId)),
      }))
      .sort((a, b) => (a.hide ? 1 : 0) - (b.hide ? 1 : 0))
      .filter((x) => !(x.hide && x.dontShowOnHide));
  };

  return (
    <Box className="product-icons">
      {filteredProducts().map((product, i) => (
        <ProductIcon
          key={product.id + '-' + i}
          {...product}
          restaurantId={restaurantId}
        />
      ))}
      <ProductIcon
        id="faq"
        label="FAQ"
        linkTo={(rId) => ``}
        restaurantId={restaurantId}
        className="faq"
      />
    </Box>
  );
};

export default ProductIcons;
